import { Injectable } from '@angular/core';
import { IAlfombra } from '../interfaces/alfombra';
import { ICortinas } from '../interfaces/cortinas';
import { IHogar } from '../interfaces/hogar';
import { IMuebleSala } from '../interfaces/mueble-sala';
import { IPintura } from '../interfaces/pintura';
import { IPuff } from '../interfaces/puff';
import { IVehiculo } from '../interfaces/vehiculo';
import { ISilla } from '../interfaces/silla';
import { Tapizado } from '../shared/enum/tapizado';
import { Material } from '../shared/enum/material';
import { IColchon } from '../interfaces/colchon';
import { ColchonSize } from '../shared/enum/colchon-size';
import { PoltronaType, PuffType, MuebleType } from '../shared/enum/puff-type';
import { ITapete } from '../interfaces/tapete';
import { CarpetType } from '../shared/enum/carpet-type';
import { SofaCamaType } from '../shared/enum/sofacama-type';

@Injectable({
  providedIn: 'root'
})
export class PriceService {

  cogines: number = 8265.25
  coginesDecorativos: number = 5600
  constructor() { }


  calculateHogarPrice(item: IHogar): number {
    let baseValue = 3
    let spacesValue = 0
    const cond = [...Array(17).keys()].filter(x => x > 1).map(x => x * 10)

    // calculate base value
    for (let i = 0; i < cond.length; i++) {
      if (item.m2 >= cond[i] && item.m2 < cond[i + 1]) {
        baseValue = i + 4
      }
    }

    // Espacios
    if (item.spaces.includes('cocina')) {
      spacesValue += baseValue * 0.4
    }
    if (item.spaces.includes('lavadora')) {
      spacesValue += baseValue * 0.05
    }
    if (item.spaces.includes('sala')) {
      spacesValue += baseValue * 0.06
    }
    if (item.spaces.includes('comedor')) {
      spacesValue += baseValue * 0.04
    }
    if (item.spaces.includes('garage')) {
      spacesValue += baseValue * 0.1
    }
    if (item.spaces.includes('patio')) {
      spacesValue += baseValue * 0.1
    }
    if (item.spaces.includes('estudio')) {
      spacesValue += baseValue * 0.12
    }
    if (item.spaces.includes('escalera')) {
      spacesValue += baseValue * 0.05
    }

    // Servicios Especiales
    let servicesValue = 0
    if (item.specialServices.includes('planchar')) {
      servicesValue += baseValue * 0.1
    }
    if (item.specialServices.includes('cocinar')) {
      servicesValue += baseValue * 0.1
    }

    // Habitaciones
    let roomPrices = 0
    for (let hab = 0; hab < item.rooms; hab++) {
      if (hab === 0)
        roomPrices += baseValue * 0.12
      else if (hab === 1)
        roomPrices += baseValue * 0.1
      else
        roomPrices += baseValue * 0.07
    }

    // Baños
    let bathPrices = 0
    for (let bath = 0; bath < item.bathrooms; bath++) {
      if (bath === 0)
        bathPrices += baseValue * 0.14
      else if (bath === 1)
        bathPrices += baseValue * 0.12
      else
        bathPrices += baseValue * 0.07
    }

    const precioHora = 13300//554

    let total = (spacesValue + servicesValue + roomPrices + bathPrices) * precioHora
    item.itemPrize = total
    return Number((item.itemPrize * item.itemCount).toFixed(2))
  }

  calculateMueblePrice(item: IMuebleSala): number {
    const cojinesSueltos = 9994 * Math.pow(item.looseCushions, 0.863)
    const cojinesDecorativos = (7.4 * Math.exp(-30) + 6200 * item.cushions) - 200 * Math.pow(item.cushions, 2)
    item.itemPrize = cojinesSueltos + cojinesDecorativos

    const formulaA = 14202 * item.places + 54936
    const formulaB = 13845 * (item.places) + 70356
    const formulaC = (formulaB * 0.08) + formulaB

    switch (item.material) {
      case Material.TELA:
        item.itemPrize += item.selectedMueble === MuebleType.SOFACAMA && item.sofacamaType === SofaCamaType.EXTENSIBLE ? formulaB : formulaA
        break
      case Material.CUERO:
        item.itemPrize += item.selectedMueble === MuebleType.SOFACAMA && item.sofacamaType === SofaCamaType.EXTENSIBLE ? formulaC : formulaB
        break
    }
    const formula = item.itemPrize * item.itemCount
    return Number(((formula * 0.1) + formula).toFixed(2))
  }


  calculatePinturaPrice(item: IPintura): number {
    // Total aproximado m2 de pared
    let metersToCollect = item.size * 2 // K40
    if (item.spaces.includes('Pared Escaleras'))
      metersToCollect += 13

    let baseValue = metersToCollect
    let spacesMts = 0
    // Espacios
    if (item.spaces.includes('Cocina')) {
      spacesMts += baseValue * 0.4
    }
    if (item.spaces.includes('Lavanderia')) {
      spacesMts += baseValue * 0.2
    }
    if (item.spaces.includes('Sala')) {
      spacesMts += baseValue * 0.2
    }
    if (item.spaces.includes('Comedor')) {
      spacesMts += baseValue * 0.2
    }
    if (item.spaces.includes('Garage')) {
      spacesMts += baseValue * 0.2
    }
    if (item.spaces.includes('Patio')) {
      spacesMts += baseValue * 0.2
    }
    if (item.spaces.includes('Estudio')) {
      spacesMts += baseValue * 0.4
    }
    if (item.spaces.includes('Otro')) {
      spacesMts += baseValue * 0.2
    }

    // Adding spaces meters
    metersToCollect += spacesMts

    const K42 = metersToCollect * this.metrosACobrarPorHabitacion(item.rooms) // metros a cobrar por habitación
    const K44 = item.bathrooms
    const roofPrize = item.toPaint.includes("Techo") ? item.size : 0 //K46

    const K53 = metersToCollect + K42 + Number(roofPrize)
    const isEmpty = item.isEmpty ? 0 : 150000 //K48

    let totalPrize = ((13395 * Math.pow(K53, -0.139)) * K53) + isEmpty
    let materialPrize = totalPrize * 0.3
    item.itemPrize = totalPrize + materialPrize

    return Number((item.itemPrize * item.itemCount).toFixed(2))
  }

  calculateCortinasPrice(item: ICortinas): number {
    item.ancho = item.hasSize ? item.ancho : 0
    item.largo = item.hasSize ? item.largo : 0

    let m2 = ((item.ancho / 100) * (item.largo / 100))

    let sutotal = ((6767.4 * (m2)) + 42811)

    let amount = item.itemCount
    let m2Total = m2 * amount
    let sutotalTotal = sutotal * amount
    let descuento = ((6767.4 * (m2Total)) + 42811)

    item.itemPrize = sutotalTotal// - descuento

    const formula = item.itemPrize
    return Number(((formula * 0.1) + formula).toFixed(2))
  }

  calculatePuffPrice(item: IPuff): number {
    switch (item.type) {
      case PuffType.PEQUENO:
        item.itemPrize = item.material === Material.TELA ? 20700 : 29325
        break
      case PuffType.MEDIANO:
        item.itemPrize = item.material === Material.TELA ? 29325 : 35800
        break
      case PuffType.GRANDE:
        item.itemPrize = item.material === Material.TELA ? 35800 : 44274
        break
      case PuffType.COMEDOR:
        item.itemPrize = item.material === Material.TELA ? 20700 : 29325
        break
    }
    const formula = item.itemPrize * item.itemCount * item.cantidad
    return Number(((formula * 0.1) + formula).toFixed(2))
  }

  calculatePoltronaPrice(item: IPuff): number {
    // if (item.muebleType === "poltronas") {}
    // Poltronas
    switch (item.type) {
      case PoltronaType.PEQUENA:
        item.itemPrize = item.material === Material.TELA ? 29325 : 35800
        break
      case PoltronaType.MEDIANA:
        item.itemPrize = item.material === Material.TELA ? 29325 : 44275
        break
      case PoltronaType.GRANDE:
        item.itemPrize = item.material === Material.TELA ? 44275 : 86020
        break
      case PoltronaType.ESCRITORIO:
        item.itemPrize = item.material === Material.TELA ? 0 : 0
        break
    }
    const formula = item.itemPrize * item.itemCount * item.cantidad
    return Number(((formula * 0.1) + formula).toFixed(2))
  }

  calculateIsabelinasPrice(item: IPuff): number {
    if (item.type === 'choche_bebe') {
      item.itemPrize = 57500
    } else if (item.type === 'cuna_pequeña') {
      item.itemPrize = 49900
    } else if (item.type === 'cuna_mediana') {
      item.itemPrize = 79900
    } else if (item.type === 'silla_escritorio_pequeña') {
      item.itemPrize = 16900 //item.material === Material.TELA ? 0 : 0
    } else if (item.type === 'silla_escritorio_grande') {
      item.itemPrize = 29900 //item.material === Material.TELA ? 0 : 0
    }
    const formula = item.itemPrize * item.itemCount * item.cantidad
    return Number(((formula * 0.1) + formula).toFixed(2))
  }

  calculateVehiculoPrice(item: IVehiculo): number {
    const isCar = item.type === "Carro"
    // CARRO
    if (isCar) {
      // Baul grande - Tela
      if (item.size === "Carro baul grande" && item.material === "Tela") {
        item.itemPrize = 132200
      }// Baul grande - Cuero
      else if (item.size === "Carro baul grande" && item.material === "Silla en cuero") {
        item.itemPrize = 147200
      }

      // Baul pequeño - Tela
      if (item.size === "Carro baul pequeño" && item.material === "Tela") {
        item.itemPrize = 109250
      }// Baul pequeño - Cuero
      else if (item.size === "Carro baul pequeño" && item.material === "Silla en cuero") {
        item.itemPrize = 120000
      }


    } else { // CAMIONETA
      // Camioneta 5 puestos
      // Tela
      if (item.size === "Camioneta 5 puestos" && item.material === "Tela") {
        item.itemPrize = 132250
      }// Cuero
      else if (item.size === "Camioneta 5 puestos" && item.material === "Sillas en cuero") {
        item.itemPrize = 151800
      }

      // Camioneta 7 puestos
      // Tela
      if (item.size === "Camioneta 7 puestos" && item.material === "Tela") {
        item.itemPrize = 151000
      }// Cuero
      else if (item.size === "Camioneta 7 puestos" && item.material === "Sillas en cuero") {
        item.itemPrize = 164000
      }
    }
    const formula = item.itemPrize * item.itemCount
    return Number(((formula * 0.15) + formula).toFixed(2))
  }

  calculateSillasComederPrice(item: ISilla) {
    switch (item.tapizado) {
      case Tapizado.SENTADERA:
        item.itemPrize = item.material === Material.TELA ? 9488 : 20700
        break
      case Tapizado.COMPLETA:
        item.itemPrize = item.material === Material.TELA ? 23400 : 29325
        break
      case Tapizado.POLTRONA:
        item.itemPrize = item.material === Material.TELA ? 29800 : 35800
        break
      case Tapizado.PUFF:
        item.itemPrize = item.material === Material.TELA ? 29900 : 35800
        break
    }
    const formula = item.itemPrize * item.itemCount * item.puestos
    return Number(((formula * 0.1) + formula).toFixed(2))
  }

  calculateColchonesPrice(item: IColchon) {
    let cabeceroPrice = this.cabeceroPrice(item.cabeceros)
    if (item.size !== ColchonSize.BEBE && item.cabeceroDoble)
      item.itemPrize + cabeceroPrice

    switch (item.size) {
      case ColchonSize.BEBE:
        item.itemPrize = 57500
        break
      case ColchonSize.KING:
        item.itemPrize = 126500
        item.baseCamaDoble && item.itemPrize + 28400
        break
      case ColchonSize.QUEEN:
        item.itemPrize = 130928
        item.baseCamaDoble && item.itemPrize + 48400
        break
      case ColchonSize.DOBLE:
        item.itemPrize = 101200
        item.baseCamaDoble && item.itemPrize + 38400
        break
      case ColchonSize.SEMIDOBLE:
        item.itemPrize = 88550
        item.baseCamaDoble && item.itemPrize + 58400
        break
      case ColchonSize.SENCILLO:
        item.itemPrize = 75900
        item.baseCamaDoble && item.itemPrize + 28400
        break
    }
    const formula = item.itemPrize * item.itemCount * item.doubleAmount
    return Number(((formula * 0.1) + formula).toFixed(2))
  }

  calculateTapetePrice(item: ITapete) {
    const size = item.hasSize ? item.size : item.standard
    const ancho = parseInt(size.split('x')[0])
    const largo = parseInt(size.split('x')[1])
    item.vacio! && item.itemPrize + 70000
    item.escaleras && item.itemPrize + (13 * 86128)
    switch (item.carpet) {
      case CarpetType.LISO:
        item.itemPrize = 2.35 * (ancho * largo) + 23862
        break
      case CarpetType.LANA:
        item.itemPrize = 3.08 * (ancho * largo) + 31918
        break
      case CarpetType.PELUDO:
        item.itemPrize = (2.76 * ancho * largo) + 28340
        break
    }
    const formula = item.itemPrize * item.itemCount * item.cantidad
    return Number(((formula * 0.1) + formula).toFixed(2))
  }


  calculateAlfombraPrice(item: IAlfombra): number {
    let meters = item.hasSize ? item.medidaAlfombra * 1 : item.medidaPropiedad * 0.80

    let emptyPrice = item.vacio ? 0 : 70000

    if (item.spaces.includes('escalera'))
      meters += 13

    if (item.spaces.includes('comedor'))
      meters += (meters * 0.2)

    if (item.spaces.includes('comedor'))
      meters += (meters * 0.2)

    if (item.spaces.includes('sala'))
      meters += (meters * 0.2)

    if (item.spaces.includes('escritorio'))
      meters += (meters * 0.4)

    meters += item.rooms * meters * 0.2

    item.itemPrize = (15525 * Math.pow(meters, 0.668)) + emptyPrice
    const formula = item.itemPrize * item.itemCount
    return Number(((formula * 0.1) + formula).toFixed(2))
  }

  cabeceroPrice(cabeceros: string): number {
    if (cabeceros === null || cabeceros === "") return 0

    let price = 0
    let cabbecerosStr = cabeceros.split(',')
    cabbecerosStr.forEach(cabecero => {
      const ancho = parseInt(cabecero.split('x')[0])
      const largo = parseInt(cabecero.split('x')[1])
      price += 10.4 * Math.pow(ancho * largo, -0.9139) // =10,4*(B35*D35)^0,9139
    })

    return price
  }

  metrosACobrarPorHabitacion(rooms: number): number {
    if (rooms === 1 || rooms === 2) return 0

    return ((5 * rooms) - 5) / 100
  }
}
