export enum PuffType {
  PEQUENO = 'pequeño',
  MEDIANO = 'mediano',
  GRANDE = 'grande',
  COMEDOR = 'comedor'
}

export enum PoltronaType {
  PEQUENA = 'pequeña',
  MEDIANA = 'mediana',
  GRANDE = 'grande',
  ESCRITORIO = 'silla-escritorio'
}

export enum MuebleType {
  MUEBLE = 'mueble',
  SOFACAMA = 'sofa_cama',
}
