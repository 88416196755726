<!-- <mat-dialog-content class="formulario"> -->
<mat-dialog-content class="container formulario-parent show">
  <h3>Formulario</h3>
  <form [formGroup]="userForm" (ngSubmit)="confirmUserDialog()">
    <div class="inputs">
      <p>Nombre</p>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Nombre y Apellido</mat-label>
        <input matInput placeholder="Ingresa tu nombre y apellido"
          [class.is-invalid]="userForm.get('nombre_apellido').invalid" formControlName="nombre_apellido">
      </mat-form-field>
      <p>Numero de Teléfono</p>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Teléfono</mat-label>
        <input matInput type="number" placeholder="Ingresa numero de teléfono" class="example-right-align"
          [class.is-invalid]="userForm.get('telefono').invalid" formControlName="telefono">
      </mat-form-field>
      <p>Correo Electronico</p>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Correo Electronico</mat-label>
        <input type="email" matInput placeholder="Ingresa tu email" [class.is-invalid]="userForm.get('email').invalid"
          formControlName="email">
      </mat-form-field>
    </div>

    <!-- INDICACIONES ESPECIALES -->
    <p class="subtitulo" style="margin: 1rem;">En este espacio puedes dejarnos indicaciones especiales:</p>
    <div class="container indicaciones">
      <mat-form-field class="area-text" appearance="outline">
        <textarea style="text-align: left" matInput formControlName="indicaciones"
          placeholder="Ej: sucio y percudido en general. Mancha en una silla, se me rego un liquido"></textarea>
      </mat-form-field>
    </div>
    <!-- FIN INDICACIONES ESPECIALES -->

    <!-- NO ENCUENTRAS LO QUE BUSCAS -->
    <p class="subtitulo">¿No encuentras lo que buscas? Escribelo aquí</p>
    <div class="container indicaciones">
      <mat-form-field class="area-text" appearance="outline">
        <textarea style="text-align: left" matInput formControlName="noencuentro"
          placeholder="Ej: Limpieza de..."></textarea>
      </mat-form-field>
    </div>
    <!-- FIN INDICACIONES ESPECIALES -->

    <!-- AUTORIZACION -->
    <div class="auth">
      <mat-checkbox class="example-margin" [(ngModel)]="authorize" [ngModelOptions]="{standalone: true}">Autorizo a que
        se me contacte por correo electronico
        o Whatsapp</mat-checkbox>
    </div>
    <!-- FIN AUTORIZACION -->

    <div class="btn-done">
      <button type="submit" class="btn-done-item no-hover-btn no-change-btn" color="primary" mat-raised-button
        [disabled]="!authorize || userForm.invalid">Confirmar</button>
      <button class="no-hover-btn" type="button" color="basic" mat-button matDialogClose>Cancelar</button>
    </div>
  </form>
</mat-dialog-content>
<!-- </mat-dialog-content> -->