<mat-dialog-content class="container text-center formulario-parent">
  <img class="my-4" src="../../../assets/icons/success.png" alt="">
  <p class="subtitulo mb-4">!Genial!</p>
  <p>Cotización enviada con éxito a revisión y agenda!</p>
  <p>Tus datos se han enviado satisfactoriamente nos pondremos en contacto contigo en un lapso de 3 horas para revisar
    tu cotización y seguir con el proceso de agendamiento de tus servicios.</p>
  <div class="btn-done mt-4">
    <button type="button" class="btn-done-item no-change-btn" color="primary" mat-raised-button (click)="toMain()"
      matDialogClose>Hecho</button>
  </div>
</mat-dialog-content>