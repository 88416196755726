
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SuccessComponent } from '../success/success.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CotizadoresService } from 'src/app/services/cotizadores.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IUser } from '../../interfaces/user';
import { CotizadoresData } from 'src/app/interfaces/cotizadores';
import { TelegramService } from '../../services/telegram.service';
import { CartComponent } from '../cart/cart.component';

@Component({
  selector: 'form-user',
  templateUrl: './form-user.component.html',
  styleUrls: ['./form-user.component.css']
})
export class FormUserComponent implements OnInit {
  userData: IUser
  userForm: FormGroup
  authorize: boolean = false
  emailValidatorRegex: string = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: CotizadoresData,
    public thisDialogRef: MatDialogRef<FormUserComponent>,
    private cotizadoresService: CotizadoresService,
    private telegramService: TelegramService) {
    // this.cotizadoresService.cotizadoresData$.subscribe(data => {
    //   this.data = data
    //   console.log('form', data)
    // })
  }
  ngOnInit(): void {
    this.initializeUserForm()
  }

  initializeUserForm() {
    let storedUserForm = (<IUser>JSON.parse(localStorage.getItem('userForm')))

    this.userForm = new FormGroup({
      nombre_apellido: new FormControl(storedUserForm ? storedUserForm.nombre_apellido : '', Validators.required),
      telefono: new FormControl(storedUserForm ? storedUserForm.telefono : '', Validators.required),
      email: new FormControl(storedUserForm ? storedUserForm.email : '', [Validators.required, Validators.pattern(this.emailValidatorRegex)]),
      noencuentro: new FormControl(storedUserForm ? storedUserForm.noencuentro : ''),
      indicaciones: new FormControl(storedUserForm ? storedUserForm.indicaciones : '')
    })
  }


  confirmUserDialog() {
    // If validated, insert userDate in cotizador Data and show Success Dialog if so
    if (this.userForm.valid) {
      // Save Userdata in localStorage
      let storedUserForm = this.userForm.value
      localStorage.setItem('userForm', JSON.stringify(storedUserForm))

      // Close userForm Dialog
      this.thisDialogRef.close()

      // Open Cart Dialog
      const dialogRef = this.dialog.open(CartComponent, {
        data: { userFormValue: this.userForm.value, mostrarPrecio: true },
        panelClass: 'cart-dialog'
      })
      dialogRef.afterClosed().subscribe(result => {
        //this.thisDialogRef.close(/*{ action: "confirmUserDialog", value: 'pepe' }*/)
      })
      // Creates OrderId
      // this.cotizadoresService.createCart().subscribe(orderId => {
      //   // HOGAR
      //   let hogarCartItems = [...this.data.hogarCartItems]
      //   this.data.hogarCartItems.forEach(x => {
      //     let cartItem = x
      //     cartItem.user = this.userForm.value
      //     cartItem.orderId = orderId
      //     hogarCartItems = hogarCartItems.filter(x => x.id !== cartItem.id)

      //     this.cotizadoresService.cotizarHogar(cartItem).subscribe(x => {
      //       // Removes the item from the cart after being sent to firebase
      //       this.cotizadoresService.updateToCart(cartItem.cotizadorType, hogarCartItems)
      //     }, error => this.showErrorMsg(x))
      //   })

      //   // VEHICULO
      //   let vehiculoCartItems = [...this.data.vehiculoCartItems]
      //   this.data.vehiculoCartItems.forEach(x => {
      //     let cartItem = x
      //     cartItem.user = this.userForm.value
      //     cartItem.orderId = orderId
      //     vehiculoCartItems = vehiculoCartItems.filter(x => x.id !== cartItem.id)

      //     this.cotizadoresService.cotizarVehiculo(cartItem).subscribe(x => {
      //       // Removes the item from the cart after being sent to firebase
      //       this.cotizadoresService.updateToCart(cartItem.cotizadorType, vehiculoCartItems)
      //     }, error => this.showErrorMsg(x))
      //   })

      //   // PINTURA
      //   let pinturaCartItems = [...this.data.pinturaCartItems]
      //   this.data.pinturaCartItems.forEach(x => {
      //     let cartItem = x
      //     cartItem.user = this.userForm.value
      //     cartItem.orderId = orderId
      //     pinturaCartItems = pinturaCartItems.filter(x => x.id !== cartItem.id)

      //     this.cotizadoresService.cotizarPintura(cartItem).subscribe(x => {
      //       // Removes the item from the cart after being sent to firebase
      //       this.cotizadoresService.updateToCart(cartItem.cotizadorType, pinturaCartItems)
      //     }, error => this.showErrorMsg(x))
      //   })

      //   // MUEBLE
      //   let muebleCartItems = [...this.data.muebleCartItems]
      //   this.data.muebleCartItems.forEach(x => {
      //     let cartItem = x
      //     cartItem.user = this.userForm.value
      //     cartItem.orderId = orderId
      //     muebleCartItems = muebleCartItems.filter(x => x.id !== cartItem.id)

      //     this.cotizadoresService.cotizarMueble(cartItem).subscribe(x => {
      //       // Removes the item from the cart after being sent to firebase
      //       this.cotizadoresService.updateToCart(cartItem.cotizadorType, muebleCartItems)
      //     }, error => this.showErrorMsg(x))
      //   })

      //   // CORTINAS
      //   let cortinasCartItems = [...this.data.cortinasCartItems]
      //   this.data.cortinasCartItems.forEach(x => {
      //     let cartItem = x
      //     cartItem.user = this.userForm.value
      //     cartItem.orderId = orderId
      //     cortinasCartItems = cortinasCartItems.filter(x => x.id !== cartItem.id)

      //     this.cotizadoresService.cotizarCortinas(cartItem).subscribe(x => {
      //       // Removes the item from the cart after being sent to firebase
      //       this.cotizadoresService.updateToCart(cartItem.cotizadorType, cortinasCartItems)
      //     }, error => this.showErrorMsg(x))
      //   })

      //   // COLCHON
      //   let colchonCartItems = [...this.data.colchonCartItems]
      //   this.data.colchonCartItems.forEach(x => {
      //     let cartItem = x
      //     cartItem.user = this.userForm.value
      //     cartItem.orderId = orderId
      //     colchonCartItems = colchonCartItems.filter(x => x.id !== cartItem.id)

      //     this.cotizadoresService.cotizarColchon(cartItem).subscribe(x => {
      //       // Removes the item from the cart after being sent to firebase
      //       this.cotizadoresService.updateToCart(cartItem.cotizadorType, colchonCartItems)
      //     }, error => this.showErrorMsg(x))
      //   })

      //   // SILLAS
      //   let sillasCartItems = [...this.data.sillasCartItems]
      //   this.data.sillasCartItems.forEach(x => {
      //     let cartItem = x
      //     cartItem.user = this.userForm.value
      //     cartItem.orderId = orderId
      //     sillasCartItems = sillasCartItems.filter(x => x.id !== cartItem.id)

      //     this.cotizadoresService.cotizarSilla(cartItem).subscribe(x => {
      //       // Removes the item from the cart after being sent to firebase
      //       this.cotizadoresService.updateToCart(cartItem.cotizadorType, sillasCartItems)
      //     }, error => this.showErrorMsg(x))
      //   })

      //   // TAPETE
      //   let tapeteCartItems = [...this.data.tapeteCartItems]
      //   this.data.tapeteCartItems.forEach(x => {
      //     let cartItem = x
      //     cartItem.user = this.userForm.value
      //     cartItem.orderId = orderId
      //     tapeteCartItems = tapeteCartItems.filter(x => x.id !== cartItem.id)

      //     this.cotizadoresService.cotizarTapete(cartItem).subscribe(x => {
      //       // Removes the item from the cart after being sent to firebase
      //       this.cotizadoresService.updateToCart(cartItem.cotizadorType, tapeteCartItems)
      //     }, error => this.showErrorMsg(x))
      //   })

      //   // ALFOMBRA
      //   let alfombraCartItems = [...this.data.alfombraCartItems]
      //   this.data.alfombraCartItems.forEach(x => {
      //     let cartItem = x
      //     cartItem.user = this.userForm.value
      //     cartItem.orderId = orderId
      //     alfombraCartItems = alfombraCartItems.filter(x => x.id !== cartItem.id)

      //     this.cotizadoresService.cotizarAlfombra(cartItem).subscribe(x => {
      //       // Removes the item from the cart after being sent to firebase
      //       this.cotizadoresService.updateToCart(cartItem.cotizadorType, alfombraCartItems)
      //     }, error => this.showErrorMsg(x))
      //   })

      //   // PUFF
      //   let puffCartItems = [...this.data.puffCartItems]
      //   this.data.puffCartItems.forEach(x => {
      //     let cartItem = x
      //     cartItem.user = this.userForm.value
      //     cartItem.orderId = orderId
      //     puffCartItems = puffCartItems.filter(x => x.id !== cartItem.id)

      //     this.cotizadoresService.cotizarPuff(cartItem).subscribe(x => {
      //       // Removes the item from the cart after being sent to firebase
      //       this.cotizadoresService.updateToCart(cartItem.cotizadorType, puffCartItems)
      //     }, error => this.showErrorMsg(x))
      //   })

      //   // POLTRONA
      //   let poltronaCartItems = [...this.data.poltronaCartItems]
      //   this.data.poltronaCartItems.forEach(x => {
      //     let cartItem = x
      //     cartItem.user = this.userForm.value
      //     cartItem.orderId = orderId
      //     poltronaCartItems = poltronaCartItems.filter(x => x.id !== cartItem.id)

      //     this.cotizadoresService.cotizarPoltrona(cartItem).subscribe(x => {
      //       // Removes the item from the cart after being sent to firebase
      //       this.cotizadoresService.updateToCart(cartItem.cotizadorType, poltronaCartItems)
      //     }, error => this.showErrorMsg(x))
      //   })

      //   // ISABELINA
      //   let isabelinaCartItems = [...this.data.isabelinaCartItems]
      //   this.data.isabelinaCartItems.forEach(x => {
      //     let cartItem = x
      //     cartItem.user = this.userForm.value
      //     cartItem.orderId = orderId
      //     isabelinaCartItems = isabelinaCartItems.filter(x => x.id !== cartItem.id)

      //     this.cotizadoresService.cotizarIsabelina(cartItem).subscribe(x => {
      //       // Removes the item from the cart after being sent to firebase
      //       this.cotizadoresService.updateToCart(cartItem.cotizadorType, isabelinaCartItems)
      //     }, error => this.showErrorMsg(x))
      //   })

      //   this.telegramService.sendTelegram(`Se ha hecho una nueva cotización con el numero de orden: ${orderId}`).subscribe(x => console.log("telegram", x))
      //   const dialogRef = this.dialog.open(SuccessComponent)
      //   dialogRef.afterClosed().subscribe(result => {
      //     this.thisDialogRef.close(/*{ action: "confirmUserDialog", value: 'pepe' }*/)
      //   })
      // })


    }
  }

  showErrorMsg(x: any) {
    alert(`Ha ocurrido un error cotizando ${x.cotizadorType}, por favor intente nuevamente.`)
  }
}

