<div class="container formulario-parent show">
    <h3 mat-dialog-title>{{data.title}}</h3>
    <div mat-dialog-content>
        <h6>{{data.messageCotizador}}.</h6>
        <h6>{{data.message}}</h6>
    </div>
    <div mat-dialog-actions style="float: right;">
        <button mat-raised-button color="primary" class="button-white-label" (click)="confirm()">Si</button>
        <button mat-raised-button class="no-change-blue-btn" mat-dialog-close (click)="closeDialog()">No</button>
    </div>
</div>