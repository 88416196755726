import { Component, Inject, OnInit } from '@angular/core';
import { CotizadoresService } from './services/cotizadores.service';
import { combineAll, filter, first, single, take, takeLast } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Event as NavigationEvent } from "@angular/router";
import { NavigationStart } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CartComponent } from './shared/cart/cart.component';
import { combineLatest, forkJoin } from 'rxjs';
import { FormUserComponent } from './shared/form-user/form-user.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  whatsappDisplayed: boolean = false

  constructor(
    private cotizadoresService: CotizadoresService,
    private router: Router,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public thisDialogRef: MatDialogRef<CartComponent>) {

    this.router.events
      .pipe(filter((event: NavigationEvent) => {
        return (event instanceof NavigationStart)
      }))
      .subscribe(
        (event: NavigationStart) => {
          if (event.navigationTrigger === 'popstate') this.cotizadoresService.setEditItem(null)
        }
      )

  }

  showCart() {
    if (this.totalCartCount === 0) {
      // Open Cart Dialog
      const dialogRef = this.dialog.open(CartComponent,
        {
          data: { cartEmpty: 'El carrito esta vacío' },
          panelClass: 'cart-dialog'
        })
      dialogRef.afterClosed().subscribe(result => {
        //this.thisDialogRef.close(/*{ action: "confirmUserDialog", value: 'pepe' }*/)
      })
    }else{
      // Open Cart Dialog
      const dialogRef = this.dialog.open(CartComponent,
        {
          data: { userFormValue: null, mostrarPrecio: false },
          panelClass: 'cart-dialog'
        })
      dialogRef.afterClosed().subscribe(result => {
        //this.thisDialogRef.close(/*{ action: "confirmUserDialog", value: 'pepe' }*/)
      })
  
      // const userDialogRef = this.dialog.open(FormUserComponent)
      // userDialogRef.afterClosed().subscribe(result => {
      //   //this.thisDialogRef.close(/*{ action: "confirmUserDialog", value: 'pepe' }*/)
      // })
    }


  }

  showWhatsApp() {
    this.whatsappDisplayed = true
  }

  hideWhatsApp() {
    this.whatsappDisplayed = false
  }

  cartVisible: boolean = false

  totalCartCount: number = 0

  ngOnInit() {
    // forkJoin({
    //   hogar: this.cotizadoresService.hogarCart$,
    //   vehiculo: this.cotizadoresService.vehiculoCart$.pipe(take(1)),
    //   pintura: this.cotizadoresService.pinturaCart$.pipe(take(1)),
    //   mueble: this.cotizadoresService.mueblesCart$.pipe(take(1)),
    //   colchones: this.cotizadoresService.colchonCart$.pipe(take(1)),
    //   sillas: this.cotizadoresService.sillasCart$.pipe(take(1)),
    //   alfombras: this.cotizadoresService.alfombraCart$.pipe(take(1)),
    //   tapetes: this.cotizadoresService.tapeteCart$.pipe(take(1)),
    //   cortinas: this.cotizadoresService.cortinasCart$.pipe(take(1)),
    //   puff: this.cotizadoresService.puffCart$.pipe(take(1)),
    //   isabelinas: this.cotizadoresService.isabelinaCart$.pipe(take(1)),
    //   poltronas: this.cotizadoresService.poltronaCart$.pipe(take(1)),
    // }
    // ).subscribe(data => {
    //   this.totalCartCount = data.vehiculo.length + data.hogar.length + data.mueble.length + data.pintura.length + data.cortinas.length +
    //     data.colchones.length + data.sillas.length + data.tapetes.length + data.alfombras.length + data.isabelinas.length + data.poltronas.length + data.puff.length
    // })

    this.cotizadoresService.vehiculoCart$.subscribe(vehiculo => {
      this.cotizadoresService.hogarCart$.subscribe(hogar => {
        this.cotizadoresService.mueblesCart$.subscribe(mueble => {
          this.cotizadoresService.pinturaCart$.subscribe(pintura => {
            this.cotizadoresService.cortinasCart$.subscribe(cortinas => {
              this.cotizadoresService.colchonCart$.subscribe(colchon => {
                this.cotizadoresService.sillasCart$.subscribe(sillas => {
                  this.cotizadoresService.tapeteCart$.subscribe(tapetes => {
                    this.cotizadoresService.alfombraCart$.subscribe(alfombras => {
                      this.cotizadoresService.isabelinaCart$.subscribe(isabelina => {
                        this.cotizadoresService.poltronaCart$.subscribe(poltrona => {
                          this.cotizadoresService.puffCart$.subscribe(puff => {
                            this.totalCartCount = vehiculo.length + hogar.length + mueble.length + pintura.length + cortinas.length +
                              colchon.length + sillas.length + tapetes.length + alfombras.length + isabelina.length + poltrona.length + puff.length
                          })
                        })
                      })
                    })
                  })
                })
              })
            })
          })
        })
      })
    })
  }
}

