// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAvcue7H8SiIQ8NrAi1hk07LzeTYn_jq1A",
    authDomain: "homsy-e9809.firebaseapp.com",
    databaseURL: "https://myapp-project-123.firebaseio.com",
    projectId: "homsy-e9809",
    storageBucket: "homsy-e9809.appspot.com",
    messagingSenderId: "245291508530",
    appId: "1:245291508530:web:8c01724db7851c6aac6d2b",
    measurementId: "G-1DZNC3F0RJ"
  }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
