import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TelegramService {
  api: string = 'bot1906483949:AAEBFs4YnL1xpirb-x4in-X4JS-hhEC2vPU'
  chatId: string = '-527229603'//'1034772144'

  private errorSource: BehaviorSubject<boolean> = new BehaviorSubject(null)
  public readonly $error: Observable<any> = this.errorSource.asObservable()

  constructor(private http: HttpClient) { }

  sendTelegram(message: string): Observable<any> {
    //return of("Hola")
    return this.http.post<any[]>(`https://api.telegram.org/${this.api}/sendMessage?chat_id=${this.chatId}&text=${message}`, null)
  }
}
//https://api.telegram.org/bot1717144006:AAHtju70E0GX6tb7kNP4iZTrd4gs29vgFVo/sendMessage?chat_id=1034772144&text=${fullMessage}
