export enum CotizadorType {
  HOGAR = 'HOGAR',
  VEHICULO = 'VEHICULO',
  POLTRONAS = 'POLTRONAS',
  PINTURA = 'PINTURA',
  MUEBLE = 'MUEBLE',
  CORTINAS = 'CORTINAS',
  COLCHON = 'COLCHON',
  ALFOMBRA = 'ALFOMBRA',
  TAPETE = 'TAPETE',
  SILLAS = 'SILLAS',
  ISABELINAS = 'ISABELINAS',
  PUFF = 'PUFF'
}
