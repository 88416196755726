<router-outlet></router-outlet>
<button mat-mini-fab color="primary" class="cart-btn no-change-btn" [matBadge]="totalCartCount" matBadgeColor="warn"
  (click)="showCart()">
  <mat-icon>toc</mat-icon>
</button>


<div class="whatsapp-popup" [ngClass]="{hidden: !whatsappDisplayed}">
  <div class="whatsapp-title">
    <div class="whatsapp_heading" style="background: rgb(45, 183, 66);">
      <div class="whatsapp_title" style="color: rgb(255, 255, 255);">Asesoría Comercial En WhatsApp.</div>
      <div class="whatsapp_intro" style="color: rgb(217, 235, 198);">Hola. Para solicitar asesoría comercial en
        WhatsApp
        haga clic en Asesoría comercial abajo.</div>
    </div>
  </div>
  <div class="whatsapp-content">
    <div class="whatsapp-msg">El equipo suele responder en pocos minutos.</div>
    <div class="whatsapp-contact-btn">
      <a class="ws_hiperlink"
        href="https://api.whatsapp.com/send?phone=573052382745&amp;text=Hola HomSy. Quiero asesoría comercial."
        target="_blank">
        <div style="position: relative; height: 100%;">
          <img src="../assets/icons/whatsapp-96.png" class="ws_firstIcon">
          <div class="ws_btn_title">Asesoría comercial</div>
          <img src="../assets/icons/ws.png" class="ws_secondIcon">
        </div>
      </a>
    </div>
  </div>
</div>


<button mat-fab color="primary" class="whatsapp-btn" (click)="showWhatsApp()" [hidden]="whatsappDisplayed">
  <img src="../assets/icons/whatsapp-96.png" style="width: 70%; margin-top: -6px;">
</button>
<button mat-fab color="primary" class="whatsapp-btn" (click)="hideWhatsApp()" [hidden]="!whatsappDisplayed">
  <mat-icon style="color: white;">close</mat-icon>
</button>


<p class="speech-bubble hidden">Tu <span id="message"></span> se ha añadido al cotizador. Haz clic aquí si
  deseas verlo.</p>