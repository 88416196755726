import { Component, Inject, OnInit } from '@angular/core';
import { CotizadoresService } from 'src/app/services/cotizadores.service';
import { IHogar } from '../../interfaces/hogar';
import { IVehiculo } from '../../interfaces/vehiculo';
import { IMuebleSala } from 'src/app/interfaces/mueble-sala';
import { IPintura } from '../../interfaces/pintura';
import { ICortinas } from '../../interfaces/cortinas';
import { IColchon } from '../../interfaces/colchon';
import { ISilla } from 'src/app/interfaces/silla';
import { CotizadorType } from '../enum/cotizador-type';
import { ITapete } from '../../interfaces/tapete';
import { IAlfombra } from '../../interfaces/alfombra';
import { Router } from '@angular/router';
import { IPuff } from '../../interfaces/puff';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormUserComponent } from '../form-user/form-user.component';
import { CotizadoresData } from 'src/app/interfaces/cotizadores';
import { PriceService } from '../../services/price.service';
import { IUser } from 'src/app/interfaces/user';
import { TelegramService } from 'src/app/services/telegram.service';
import { SuccessComponent } from '../success/success.component';
import html2canvas from 'html2canvas';
import { AngularFireStorage } from '@angular/fire/storage';
@Component({
  selector: 'cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})

export class CartComponent implements OnInit {
  subtotal: number = 0
  iva: number = 0
  //total: number = 0
  hogarCartItems: IHogar[] = []
  vehiculoCartItems: IVehiculo[] = []
  muebleCartItems: IMuebleSala[] = []
  pinturaCartItems: IPintura[] = []
  cortinasCartItems: ICortinas[] = []
  colchonCartItems: IColchon[] = []
  sillasCartItems: ISilla[] = []
  tapeteCartItems: ITapete[] = []
  alfombraCartItems: IAlfombra[] = []
  isabelinaCartItems: IPuff[] = []
  poltronaCartItems: IPuff[] = []
  puffCartItems: IPuff[] = []

  // Price Variables
  hogarPrice: number = 0
  mueblePrice: number = 0
  pinturaPrice: number = 0
  cortinasPrice: number = 0
  puffPrice: number = 0
  isabelinasPrice: number = 0
  vehiculoPrice: number = 0
  poltronaPrice: number = 0
  sillasComederPrice: number = 0
  colchonPrice: number = 0
  tapetePrice: number = 0
  alfombraPrice: number = 0

  constructor(
    private cotizadoresService: CotizadoresService,
    private route: Router,
    public thisDialogRef: MatDialogRef<CartComponent>,
    private dialog: MatDialog,
    private priceSvc: PriceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private telegramService: TelegramService,
    private fireStorage: AngularFireStorage) {

    console.log("data", this.data)
  }

  ngOnInit(): void {
    this.getAllItems()
  }

  sendEmail() {
    //this.data.userFormValue.email
    this.genScreenshot()
    // location.href = `mailto:`
  }

  genScreenshot() {
    // var canvasImgContentDecoded;
    // html2canvas(document.body).then((canvas) => {
    //   (<any>window).canvasImgContentDecoded = canvas.toDataURL("image/png")
    // });

    html2canvas(document.body).then(canvas => {
      console.log("canvas", canvas)

      let base64URL = canvas.toDataURL('image/jpeg')//.replace('image/jpeg', 'image/octet-stream')
      console.log("base64URL", base64URL)
      //location.href = base64URL

      fetch(base64URL)
        .then(res => {
          res.blob().then(x => {

            //let imgUrl = window.URL.createObjectURL(x)
            // location.href = img
            //open(imgUrl)
            //const file = new File([x], "File name", { type: "image/jpeg" })

            // Save to server
            // const file = base64URL
            //generates an ID for uniqueness purposes
            const id = Math.random().toFixed() + (new Date()).getTime()
            // unique filename
            const path = `/files-screenshot-${id}`

            // Upload file to firebase
            this.fireStorage.upload(path, x).then(response => {
              console.log("response", response)
              // Save server link in the Item
              const url = `https://firebasestorage.googleapis.com/v0/b/${this.cotizadoresService.domain}/o${path}?alt=media`
              //this.hogar.fotos.push(`https://firebasestorage.googleapis.com/v0/b/${this.cotizadoresService.domain}/o${path}?alt=media`)
              console.log("url", url)
              const cotizadorUrl = 'https://cotizador.homsy.com.co/'
              const shareData = {
                title: 'Homsy',
                text: `Cotiza, agenda y disfruta tus HomSy servicios de limpieza y pintura en Bogotá, Cali,  Barranquilla, Santa Marta y Cartagena.\n
                ${url}\n
                Si quieres agendar tu servicio cotiza en este link`,
                url: cotizadorUrl,
                // files: [file]
              }

              navigator.share(shareData)
              // location.href = url
            }, err => {
              console.log("err", err)

            })
          })







        })
      // .then(console.log)



      //document.body.appendChild(canvas)
    });
  }

  getAllItems() {
    this.getHogarItems()
    this.getVehiculoItems()
    this.getMuebleItems()
    this.getPinturaItems()
    this.getCortinasItems()
    this.getColchonItems()
    this.getSillasItems()
    this.getTapetesItems()
    this.getAlfombrasItems()
    this.getPuffItems()
    this.getPoltronasItems()
    this.getIsabelinasItems()
  }

  noItems(): boolean {
    return (this.hogarCartItems.length +
      this.vehiculoCartItems.length +
      this.muebleCartItems.length +
      this.pinturaCartItems.length +
      this.cortinasCartItems.length +
      this.colchonCartItems.length +
      this.sillasCartItems.length +
      this.tapeteCartItems.length +
      this.alfombraCartItems.length +
      this.isabelinaCartItems.length +
      this.poltronaCartItems.length +
      this.puffCartItems.length) === 0
  }

  calculateCartPrices() {
    console.log("calculating prices")
    this.hogarPrice = 0
    this.mueblePrice = 0
    this.pinturaPrice = 0
    this.cortinasPrice = 0
    this.puffPrice = 0
    this.isabelinasPrice = 0
    this.vehiculoPrice = 0
    this.poltronaPrice = 0
    this.sillasComederPrice = 0
    this.colchonPrice = 0
    this.tapetePrice = 0
    this.alfombraPrice = 0

    this.hogarCartItems.forEach(i => { this.hogarPrice += this.priceSvc.calculateHogarPrice(i) })
    this.muebleCartItems.forEach(i => { this.mueblePrice += this.priceSvc.calculateMueblePrice(i) })
    this.pinturaCartItems.forEach(i => { this.pinturaPrice += this.priceSvc.calculatePinturaPrice(i) })
    this.cortinasCartItems.forEach(i => { this.cortinasPrice += this.priceSvc.calculateCortinasPrice(i) })
    this.puffCartItems.forEach(i => { this.puffPrice += this.priceSvc.calculatePuffPrice(i) })
    this.isabelinaCartItems.forEach(i => { this.isabelinasPrice += this.priceSvc.calculateIsabelinasPrice(i) })
    this.vehiculoCartItems.forEach(i => { this.vehiculoPrice += this.priceSvc.calculateVehiculoPrice(i) })
    this.poltronaCartItems.forEach(i => { this.poltronaPrice += this.priceSvc.calculatePoltronaPrice(i) })
    this.sillasCartItems.forEach(i => { this.sillasComederPrice += this.priceSvc.calculateSillasComederPrice(i) })
    this.colchonCartItems.forEach(i => { this.colchonPrice += this.priceSvc.calculateColchonesPrice(i) })
    this.tapeteCartItems.forEach(i => { this.tapetePrice += this.priceSvc.calculateTapetePrice(i) })
    this.alfombraCartItems.forEach(i => { this.alfombraPrice += this.priceSvc.calculateAlfombraPrice(i) })
  }

  calculateHogarPrice(item: IHogar): number {
    // this.hogarPrice = 0
    // this.hogarCartItems.forEach(i => {
    //   this.hogarPrice += this.priceSvc.calculateHogarPrice(i)
    // })
    return this.priceSvc.calculateHogarPrice(item)
  }



  calculateMueblePrice(item: IMuebleSala): number {
    // this.mueblePrice = 0
    // this.muebleCartItems.forEach(i => {
    //   this.mueblePrice += this.priceSvc.calculateMueblePrice(i)
    // })

    return this.priceSvc.calculateMueblePrice(item)
  }

  calculatePinturaPrice(item: IPintura): number {
    // this.pinturaPrice = 0
    // this.pinturaCartItems.forEach(i => {
    //   this.pinturaPrice += this.priceSvc.calculatePinturaPrice(i)
    // })

    return this.priceSvc.calculatePinturaPrice(item)
  }

  calculateCortinasPrice(item: ICortinas): number {
    // this.cortinasPrice = 0
    // this.cortinasCartItems.forEach(i => {
    //   this.cortinasPrice += this.priceSvc.calculateCortinasPrice(i)
    // })

    return this.priceSvc.calculateCortinasPrice(item)
  }

  calculatePuffPrice(item: IPuff): number {
    // this.puffPrice = 0
    // this.puffCartItems.forEach(i => {
    //   this.puffPrice += this.priceSvc.calculatePuffPrice(i)
    // })

    return this.priceSvc.calculatePuffPrice(item)
  }

  calculateIsabelinasPrice(item: IPuff): number {
    // this.isabelinasPrice = 0
    // this.isabelinaCartItems.forEach(i => {
    //   this.isabelinasPrice += this.priceSvc.calculateIsabelinasPrice(i)
    // })

    return this.priceSvc.calculateIsabelinasPrice(item)
  }

  calculateVehiculoPrice(item: IVehiculo): number {
    // this.vehiculoPrice = 0
    // this.vehiculoCartItems.forEach(i => {
    //   this.vehiculoPrice += this.priceSvc.calculateVehiculoPrice(i)
    // })

    return this.priceSvc.calculateVehiculoPrice(item)
  }

  calculatePoltronaPrice(item: IPuff): number {
    // this.poltronaPrice = 0
    // this.poltronaCartItems.forEach(i => {
    //   this.poltronaPrice += this.priceSvc.calculatePoltronaPrice(i)
    // })

    return this.priceSvc.calculatePoltronaPrice(item)
  }

  calculateSillasComederPrice(item: ISilla): number {
    // this.sillasComederPrice = 0
    // this.sillasCartItems.forEach(i => {
    //   this.sillasComederPrice += this.priceSvc.calculateSillasComederPrice(i)
    // })

    return this.priceSvc.calculateSillasComederPrice(item)
  }

  calculateColchonPrice(item: IColchon): number {
    // this.colchonPrice = 0
    // this.colchonCartItems.forEach(i => {
    //   this.colchonPrice += this.priceSvc.calculateColchonesPrice(i)
    // })

    return this.priceSvc.calculateColchonesPrice(item)
  }

  calculateTapetePrice(item: ITapete): number {
    // this.tapetePrice = 0
    // this.tapeteCartItems.forEach(i => {
    //   this.tapetePrice += this.priceSvc.calculateTapetePrice(i)
    // })

    return this.priceSvc.calculateTapetePrice(item)
  }

  calculateAlfombraPrice(item: IAlfombra): number {
    // this.alfombraPrice = 0
    // this.alfombraCartItems.forEach(i => {
    //   this.alfombraPrice += this.priceSvc.calculateAlfombraPrice(i)
    // })

    return this.priceSvc.calculateAlfombraPrice(item)
  }

  totalPrice(): number {
    const sum =
      this.hogarPrice +
      this.mueblePrice +
      this.pinturaPrice +
      this.cortinasPrice +
      this.puffPrice +
      this.isabelinasPrice +
      this.vehiculoPrice +
      this.poltronaPrice +
      this.sillasComederPrice +
      this.colchonPrice +
      this.tapetePrice +
      this.alfombraPrice

    return sum
  }

  getIsabelinasItems() {
    this.cotizadoresService.isabelinaCart$.subscribe(items => { this.isabelinaCartItems = items; this.calculateCartPrices() })
  }

  getPoltronasItems() {
    this.cotizadoresService.poltronaCart$.subscribe(items => {
      this.poltronaCartItems = items; this.calculateCartPrices()
    })
  }

  getPuffItems() {
    this.cotizadoresService.puffCart$.subscribe(items => {
      this.puffCartItems = items; this.calculateCartPrices()
    })
  }

  getHogarItems() {
    this.cotizadoresService.hogarCart$.subscribe(items => {
      this.hogarCartItems = items; this.calculateCartPrices()
    })
  }

  getVehiculoItems() {
    this.cotizadoresService.vehiculoCart$.subscribe(items => {
      this.vehiculoCartItems = items; this.calculateCartPrices()
    })
  }

  getMuebleItems() {
    this.cotizadoresService.mueblesCart$.subscribe(items => {
      this.muebleCartItems = items; this.calculateCartPrices()
    })
  }

  getPinturaItems() {
    this.cotizadoresService.pinturaCart$.subscribe(items => {
      this.pinturaCartItems = items; this.calculateCartPrices()
    })
  }

  getCortinasItems() {
    this.cotizadoresService.cortinasCart$.subscribe(items => {
      this.cortinasCartItems = items; this.calculateCartPrices()
    })
  }

  getColchonItems() {
    this.cotizadoresService.colchonCart$.subscribe(items => {
      this.colchonCartItems = items; this.calculateCartPrices()
    })
  }

  getSillasItems() {
    this.cotizadoresService.sillasCart$.subscribe(items => {
      this.sillasCartItems = items; this.calculateCartPrices()
    })
  }

  getTapetesItems() {
    this.cotizadoresService.tapeteCart$.subscribe(items => {
      this.tapeteCartItems = items; this.calculateCartPrices()
    })
  }

  getAlfombrasItems() {
    this.cotizadoresService.alfombraCart$.subscribe(items => {
      this.alfombraCartItems = items; this.calculateCartPrices()
    })
  }

  removeItem(item: any) {
    const type = item.cotizadorType
    let items: any[] = []

    switch (type) {
      case CotizadorType.HOGAR:
        items = this.hogarCartItems.filter(x => x.id !== item.id)
        break
      case CotizadorType.VEHICULO:
        items = this.vehiculoCartItems.filter(x => x.id !== item.id)
        break
      case CotizadorType.MUEBLE:
        items = this.muebleCartItems.filter(x => x.id !== item.id)
        break
      case CotizadorType.PINTURA:
        items = this.pinturaCartItems.filter(x => x.id !== item.id)
        break
      case CotizadorType.CORTINAS:
        items = this.cortinasCartItems.filter(x => x.id !== item.id)
        break
      case CotizadorType.COLCHON:
        items = this.colchonCartItems.filter(x => x.id !== item.id)
        break
      case CotizadorType.SILLAS:
        items = this.sillasCartItems.filter(x => x.id !== item.id)
        break
      case CotizadorType.TAPETE:
        items = this.tapeteCartItems.filter(x => x.id !== item.id)
        break
      case CotizadorType.ALFOMBRA:
        items = this.alfombraCartItems.filter(x => x.id !== item.id)
        break
      case CotizadorType.PUFF:
        items = this.puffCartItems.filter(x => x.id !== item.id)
        break
      case CotizadorType.POLTRONAS:
        items = this.poltronaCartItems.filter(x => x.id !== item.id)
        break
      case CotizadorType.ISABELINAS:
        items = this.isabelinaCartItems.filter(x => x.id !== item.id)
        break
    }

    this.cotizadoresService.updateToCart(item.cotizadorType, items)
  }

  addCountItem(item: any, items: any[]) {
    item.itemCount++
    this.cotizadoresService.updateToCart(item.cotizadorType, items)
  }

  removeCountItem(item: any, items: any[]) {
    item.itemCount > 1 && item.itemCount--
    this.cotizadoresService.updateToCart(item.cotizadorType, items)
  }

  editItem(item: any) {
    switch (item.cotizadorType) {
      case CotizadorType.HOGAR:
        this.route.navigate(['/hogar'])
        break
      case CotizadorType.VEHICULO:
        this.route.navigate(['/vehiculo'])
        break
      case CotizadorType.PINTURA:
        this.route.navigate(['/pintura'])
        break
      case CotizadorType.MUEBLE:
      case CotizadorType.CORTINAS:
      case CotizadorType.COLCHON:
      case CotizadorType.SILLAS:
      case CotizadorType.ALFOMBRA:
      case CotizadorType.TAPETE:
      case CotizadorType.POLTRONAS:
      case CotizadorType.PUFF:
      case CotizadorType.ISABELINAS:
        this.route.navigate(['/muebles'])
        break
    }

    this.cotizadoresService.setEditItem(item)
    this.thisDialogRef.close()
  }

  cotizar() {
    if (this.data.mostrarPrecio) {
      this.processOrder()
    } else {
      this.setupUserForm()
    }
  }

  setupUserForm() {
    // Cerramos el carrito con precio oculto
    this.thisDialogRef.close()

    // Abrimos el formulario de usuario
    const userDialogRef = this.dialog.open(FormUserComponent, {
      panelClass: 'form-user-comp'
    })
    userDialogRef.afterClosed().subscribe(result => {
      //this.thisDialogRef.close(/*{ action: "confirmUserDialog", value: 'pepe' }*/)
    })
    //  const cotizadoresData: CotizadoresData = {
    //    hogarCartItems: this.hogarCartItems,
    //    vehiculoCartItems: this.vehiculoCartItems,
    //    muebleCartItems: this.muebleCartItems,
    //    pinturaCartItems: this.pinturaCartItems,
    //    cortinasCartItems: this.cortinasCartItems,
    //    colchonCartItems: this.colchonCartItems,
    //    sillasCartItems: this.sillasCartItems,
    //    tapeteCartItems: this.tapeteCartItems,
    //    alfombraCartItems: this.alfombraCartItems,
    //    isabelinaCartItems: this.isabelinaCartItems,
    //    poltronaCartItems: this.poltronaCartItems,
    //    puffCartItems: this.puffCartItems,
    //  }

    // this.cotizadoresService.setCotizadoresData(cotizadoresData)

    // const dialogRef = this.dialog.open(FormUserComponent, { data: cotizadoresData })
    // this.thisDialogRef.close()
    //  dialogRef.afterClosed().subscribe(result => {
    //    console.log(`User Dialog result:`, result)
    //  })
  }

  processOrder() {
    console.log("data", this.data.userFormValue)
    // Creates OrderId
    this.cotizadoresService.createCart().subscribe(orderId => {
      // HOGAR
      let hogarCartItems = [...this.hogarCartItems]
      this.hogarCartItems.forEach(x => {
        let cartItem = x
        cartItem.user = this.data.userFormValue
        cartItem.orderId = orderId
        cartItem.dateCreated = Date.now()
        hogarCartItems = hogarCartItems.filter(x => x.id !== cartItem.id)

        this.cotizadoresService.cotizarHogar(cartItem).subscribe(x => {
          // Removes the item from the cart after being sent to firebase
          this.cotizadoresService.updateToCart(cartItem.cotizadorType, hogarCartItems)
        }, error => this.showErrorMsg(x))
      })

      // VEHICULO
      let vehiculoCartItems = [...this.vehiculoCartItems]
      this.vehiculoCartItems.forEach(x => {
        let cartItem = x
        cartItem.user = this.data.userFormValue
        cartItem.orderId = orderId
        cartItem.dateCreated = Date.now()
        vehiculoCartItems = vehiculoCartItems.filter(x => x.id !== cartItem.id)

        this.cotizadoresService.cotizarVehiculo(cartItem).subscribe(x => {
          // Removes the item from the cart after being sent to firebase
          this.cotizadoresService.updateToCart(cartItem.cotizadorType, vehiculoCartItems)
        }, error => this.showErrorMsg(x))
      })

      // PINTURA
      let pinturaCartItems = [...this.pinturaCartItems]
      this.pinturaCartItems.forEach(x => {
        let cartItem = x
        cartItem.user = this.data.userFormValue
        cartItem.orderId = orderId
        cartItem.dateCreated = Date.now()
        pinturaCartItems = pinturaCartItems.filter(x => x.id !== cartItem.id)

        this.cotizadoresService.cotizarPintura(cartItem).subscribe(x => {
          // Removes the item from the cart after being sent to firebase
          this.cotizadoresService.updateToCart(cartItem.cotizadorType, pinturaCartItems)
        }, error => this.showErrorMsg(x))
      })

      // MUEBLE
      let muebleCartItems = [...this.muebleCartItems]
      this.muebleCartItems.forEach(x => {
        let cartItem = x
        cartItem.user = this.data.userFormValue
        cartItem.orderId = orderId
        cartItem.dateCreated = Date.now()
        muebleCartItems = muebleCartItems.filter(x => x.id !== cartItem.id)

        this.cotizadoresService.cotizarMueble(cartItem).subscribe(x => {
          // Removes the item from the cart after being sent to firebase
          this.cotizadoresService.updateToCart(cartItem.cotizadorType, muebleCartItems)
        }, error => this.showErrorMsg(x))
      })

      // CORTINAS
      let cortinasCartItems = [...this.cortinasCartItems]
      this.cortinasCartItems.forEach(x => {
        let cartItem = x
        cartItem.user = this.data.userFormValue
        cartItem.orderId = orderId
        cartItem.dateCreated = Date.now()
        cortinasCartItems = cortinasCartItems.filter(x => x.id !== cartItem.id)

        this.cotizadoresService.cotizarCortinas(cartItem).subscribe(x => {
          // Removes the item from the cart after being sent to firebase
          this.cotizadoresService.updateToCart(cartItem.cotizadorType, cortinasCartItems)
        }, error => this.showErrorMsg(x))
      })

      // COLCHON
      let colchonCartItems = [...this.colchonCartItems]
      this.colchonCartItems.forEach(x => {
        let cartItem = x
        cartItem.user = this.data.userFormValue
        cartItem.orderId = orderId
        cartItem.dateCreated = Date.now()
        colchonCartItems = colchonCartItems.filter(x => x.id !== cartItem.id)

        this.cotizadoresService.cotizarColchon(cartItem).subscribe(x => {
          // Removes the item from the cart after being sent to firebase
          this.cotizadoresService.updateToCart(cartItem.cotizadorType, colchonCartItems)
        }, error => this.showErrorMsg(x))
      })

      // SILLAS
      let sillasCartItems = [...this.sillasCartItems]
      this.sillasCartItems.forEach(x => {
        let cartItem = x
        cartItem.user = this.data.userFormValue
        cartItem.orderId = orderId
        cartItem.dateCreated = Date.now()
        sillasCartItems = sillasCartItems.filter(x => x.id !== cartItem.id)

        this.cotizadoresService.cotizarSilla(cartItem).subscribe(x => {
          // Removes the item from the cart after being sent to firebase
          this.cotizadoresService.updateToCart(cartItem.cotizadorType, sillasCartItems)
        }, error => this.showErrorMsg(x))
      })

      // TAPETE
      let tapeteCartItems = [...this.tapeteCartItems]
      this.tapeteCartItems.forEach(x => {
        let cartItem = x
        cartItem.user = this.data.userFormValue
        cartItem.orderId = orderId
        cartItem.dateCreated = Date.now()
        tapeteCartItems = tapeteCartItems.filter(x => x.id !== cartItem.id)

        this.cotizadoresService.cotizarTapete(cartItem).subscribe(x => {
          // Removes the item from the cart after being sent to firebase
          this.cotizadoresService.updateToCart(cartItem.cotizadorType, tapeteCartItems)
        }, error => this.showErrorMsg(x))
      })

      // ALFOMBRA
      let alfombraCartItems = [...this.alfombraCartItems]
      this.alfombraCartItems.forEach(x => {
        let cartItem = x
        cartItem.user = this.data.userFormValue
        cartItem.orderId = orderId
        cartItem.dateCreated = Date.now()
        alfombraCartItems = alfombraCartItems.filter(x => x.id !== cartItem.id)

        this.cotizadoresService.cotizarAlfombra(cartItem).subscribe(x => {
          // Removes the item from the cart after being sent to firebase
          this.cotizadoresService.updateToCart(cartItem.cotizadorType, alfombraCartItems)
        }, error => this.showErrorMsg(x))
      })

      // PUFF
      let puffCartItems = [...this.puffCartItems]
      this.puffCartItems.forEach(x => {
        let cartItem = x
        cartItem.user = this.data.userFormValue
        cartItem.orderId = orderId
        cartItem.dateCreated = Date.now()
        puffCartItems = puffCartItems.filter(x => x.id !== cartItem.id)

        this.cotizadoresService.cotizarPuff(cartItem).subscribe(x => {
          // Removes the item from the cart after being sent to firebase
          this.cotizadoresService.updateToCart(cartItem.cotizadorType, puffCartItems)
        }, error => this.showErrorMsg(x))
      })

      // POLTRONA
      let poltronaCartItems = [...this.poltronaCartItems]
      this.poltronaCartItems.forEach(x => {
        let cartItem = x
        cartItem.user = this.data.userFormValue
        cartItem.orderId = orderId
        cartItem.dateCreated = Date.now()
        poltronaCartItems = poltronaCartItems.filter(x => x.id !== cartItem.id)

        this.cotizadoresService.cotizarPoltrona(cartItem).subscribe(x => {
          // Removes the item from the cart after being sent to firebase
          this.cotizadoresService.updateToCart(cartItem.cotizadorType, poltronaCartItems)
        }, error => this.showErrorMsg(x))
      })

      // ISABELINA
      let isabelinaCartItems = [...this.isabelinaCartItems]
      this.isabelinaCartItems.forEach(x => {
        let cartItem = x
        cartItem.user = this.data.userFormValue
        cartItem.orderId = orderId
        cartItem.dateCreated = Date.now()
        isabelinaCartItems = isabelinaCartItems.filter(x => x.id !== cartItem.id)

        this.cotizadoresService.cotizarIsabelina(cartItem).subscribe(x => {
          // Removes the item from the cart after being sent to firebase
          this.cotizadoresService.updateToCart(cartItem.cotizadorType, isabelinaCartItems)
        }, error => this.showErrorMsg(x))
      })

      this.telegramService.sendTelegram(`Se ha hecho una nueva cotización con el numero de orden: ${orderId}`).subscribe(x => console.log("telegram", x))
      const dialogRef = this.dialog.open(SuccessComponent)
      dialogRef.afterClosed().subscribe(result => {
        this.thisDialogRef.close(/*{ action: "confirmUserDialog", value: 'pepe' }*/)
      })
    })
  }

  showErrorMsg(x: any) {
    alert(`Ha ocurrido un error cotizando ${x.cotizadorType}, por favor intente nuevamente.`)
  }
}
