import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // { path: '', loadChildren: () => import('./modules/landing-page/landing-page.module').then(m => m.LandingPageModule) },
  { path: '', loadChildren: () => import('./modules/cotizadores/cotizadores.module').then(m => m.CotizadoresModule) },
  { path: 'hogar', loadChildren: () => import('./modules/cotizador_hogar/cotizador_hogar.module').then(m => m.CotizadorHogarModule) },
  { path: 'vehiculo', loadChildren: () => import('./modules/cotizador-vehiculo/cotizador-vehiculo.module').then(m => m.CotizadorVehiculoModule) },
  { path: 'muebles', loadChildren: () => import('./modules/cotizador-muebles/cotizador-muebles.module').then(m => m.CotizadorMueblesModule) },
  { path: 'alfombra', loadChildren: () => import('./modules/cotizador_alfombra/cotizador_alfombra.module').then(m => m.CotizadorAlfombraModule) },
  { path: 'pintura', loadChildren: () => import('./modules/cotizador-pintura/cotizador-pintura.module').then(m => m.CotizadorPinturaModule) },
  { path: 'crm', loadChildren: () => import('./modules/crm/crm.module').then(m => m.CRMModule) },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
