import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IHogar } from '../interfaces/hogar';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { IPintura } from '../interfaces/pintura';
import { IVehiculo } from '../interfaces/vehiculo';
import { CotizadorType } from '../shared/enum/cotizador-type';
import { IMuebleSala } from '../interfaces/mueble-sala';
import { ICortinas } from '../interfaces/cortinas';
import { IColchon } from '../interfaces/colchon';
import { ISilla } from '../interfaces/silla';
import { IAlfombra } from '../interfaces/alfombra';
import { ITapete } from '../interfaces/tapete';
import { IPuff } from '../interfaces/puff';
import { CotizadoresData } from '../interfaces/cotizadores';
import { IOrder } from '../interfaces/order';
import { ComfirmDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
@Injectable({
  providedIn: 'root'
})
export class CotizadoresService {
  // Cart Observavbles
  private _hogarCart: BehaviorSubject<IHogar[]> = new BehaviorSubject([])
  public readonly hogarCart$: Observable<IHogar[]> = this._hogarCart.asObservable()

  private _vehiculoCart: BehaviorSubject<IVehiculo[]> = new BehaviorSubject([])
  public readonly vehiculoCart$: Observable<IVehiculo[]> = this._vehiculoCart.asObservable()

  private _mueblesCart: BehaviorSubject<IMuebleSala[]> = new BehaviorSubject([])
  public readonly mueblesCart$: Observable<IMuebleSala[]> = this._mueblesCart.asObservable()

  private _pinturaCart: BehaviorSubject<IPintura[]> = new BehaviorSubject([])
  public readonly pinturaCart$: Observable<IPintura[]> = this._pinturaCart.asObservable()

  private _cortinasCart: BehaviorSubject<ICortinas[]> = new BehaviorSubject([])
  public readonly cortinasCart$: Observable<ICortinas[]> = this._cortinasCart.asObservable()

  private _colchonCart: BehaviorSubject<IColchon[]> = new BehaviorSubject([])
  public readonly colchonCart$: Observable<IColchon[]> = this._colchonCart.asObservable()

  private _sillasCart: BehaviorSubject<ISilla[]> = new BehaviorSubject([])
  public readonly sillasCart$: Observable<ISilla[]> = this._sillasCart.asObservable()

  private _alfombraCart: BehaviorSubject<IAlfombra[]> = new BehaviorSubject([])
  public readonly alfombraCart$: Observable<IAlfombra[]> = this._alfombraCart.asObservable()

  private _tapeteCart: BehaviorSubject<ITapete[]> = new BehaviorSubject([])
  public readonly tapeteCart$: Observable<ITapete[]> = this._tapeteCart.asObservable()

  private _poltronaCart: BehaviorSubject<IPuff[]> = new BehaviorSubject([])
  public readonly poltronaCart$: Observable<IPuff[]> = this._poltronaCart.asObservable()

  private _puffCart: BehaviorSubject<IPuff[]> = new BehaviorSubject([])
  public readonly puffCart$: Observable<IPuff[]> = this._puffCart.asObservable()

  private _isabelinaCart: BehaviorSubject<IPuff[]> = new BehaviorSubject([])
  public readonly isabelinaCart$: Observable<IPuff[]> = this._isabelinaCart.asObservable()

  private _editItem: BehaviorSubject<any> = new BehaviorSubject(null)
  public readonly editItem$: Observable<any> = this._editItem.asObservable()


  private _uploading: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public readonly uploading$: Observable<boolean> = this._uploading.asObservable()
  // private _cotizadoresData: BehaviorSubject<CotizadoresData> = new BehaviorSubject(null)
  // public readonly cotizadoresData$: Observable<CotizadoresData> = this._cotizadoresData.asObservable()

  cotizadores = [
    { name: "Hogar", icon: "hogar", type: 'hogar' },
    { name: "Vehículos", icon: "camioneta", type: 'vehiculos' },
    { name: "Pintura", icon: "rodillo-de-pintura", type: 'pintura' },
    { name: "Muebles", icon: "sofa", type: 'muebles' },
    { name: "Alfombras", icon: "alfombra", type: 'alfombras' },
    { name: "Colchones", icon: "cama", type: 'colchones' },
    { name: "Tapetes", icon: "tapete", type: 'tapetes' },
    { name: "Cortinas", icon: "persianas", type: 'cortinas' },
    { name: "Sillas", icon: "silla", type: 'sillas' }
  ]

  domain: string = 'homsy-e9809.appspot.com'
  cartCollection: AngularFirestoreCollection<IOrder>
  hogarCollection: AngularFirestoreCollection<IHogar>
  vehiculoCollection: AngularFirestoreCollection<IVehiculo>
  pinturaCollection: AngularFirestoreCollection<IPintura>
  muebleCollection: AngularFirestoreCollection<IMuebleSala>
  colchonCollection: AngularFirestoreCollection<IColchon>
  sillaCollection: AngularFirestoreCollection<ISilla>
  alfombraCollection: AngularFirestoreCollection<IAlfombra>
  tapeteCollection: AngularFirestoreCollection<ITapete>
  cortinasCollection: AngularFirestoreCollection<ICortinas>
  puffCollection: AngularFirestoreCollection<IPuff>
  poltronaCollection: AngularFirestoreCollection<IPuff>
  isabelinaCollection: AngularFirestoreCollection<IPuff>

  constructor(private firestore: AngularFirestore, private dialog: MatDialog) {
    //localStorage.clear()
    // console.log(localStorage.removeItem('colchonCart'))
    // Get Local Data
    this.getLocalCartData()

    // Get FireStore Collections
    this.hogarCollection = this.firestore.collection<IHogar>('hogar')
    this.vehiculoCollection = this.firestore.collection<IVehiculo>('vehiculo')
    this.pinturaCollection = this.firestore.collection<IPintura>('pintura')
    this.muebleCollection = this.firestore.collection<IMuebleSala>('mueble_sala')
    this.colchonCollection = this.firestore.collection<IColchon>('colchon')
    this.sillaCollection = this.firestore.collection<ISilla>('silla')
    this.alfombraCollection = this.firestore.collection<IAlfombra>('alfombra')
    this.tapeteCollection = this.firestore.collection<ITapete>('tapete')
    this.cortinasCollection = this.firestore.collection<ICortinas>('cortinas')
    this.puffCollection = this.firestore.collection<IPuff>('puff')
    this.poltronaCollection = this.firestore.collection<IPuff>('poltronas')
    this.isabelinaCollection = this.firestore.collection<IPuff>('isabelinas')
    this.cartCollection = this.firestore.collection<IOrder>('order')
  }

  setEditItem(item: any) {
    this._editItem.next(item)
  }

  setUploading(uploading: boolean) {
    this._uploading.next(uploading)
  }

  updateDataManually() {
    this.vehiculoCollection.valueChanges({ idField: 'temp_id' }).subscribe(x => {
      console.log("")

      x.forEach(item => {
        this.firestore
          .collection('vehiculo')
          .doc('/' + item.temp_id)
          .update({ dateCreated: item.id })
          .then(() => {
            console.log('done');
          })
          .catch(function (error) {
            console.error('Error writing document: ', error);
          })
      })
    })
  }

  /**
   * Guarda en firebase lo que se quiere cotizar (El objeto hogar a cotizar)
   * @param hogar los datos que se quieren citizar
   * @returns
   */
  cotizarHogar(hogar: IHogar): Observable<string> {
    return new Observable(observer => {
      this.hogarCollection.add(hogar).then(hogar => {
        observer.next(hogar.id)
        observer.complete()
      }).catch(e => {
        observer.error(e)
      })
    })
  }

  createCart(): Observable<string> {
    return new Observable(observer => {
      this.cartCollection.add({ date: new Date }).then(order => {
        observer.next(order.id)
        observer.complete()
      }).catch(e => {
        observer.error(e)
      })
    })
  }

  /**
  * Guarda en firebase lo que se quiere cotizar
  * @param hogar los datos que se quieren citizar
  * @returns
  */
  cotizarVehiculo(vehiculo: IVehiculo): Observable<string> {
    return new Observable(observer => {
      this.vehiculoCollection.add(vehiculo).then(vehiculo => {
        observer.next(vehiculo.id)
        observer.complete()
      }).catch(e => {
        observer.error(e)
      })
    })
  }

  /**
   * Guarda en firebase lo que se quiere cotizar (El objeto hogar a cotizar)
   * @param pintura los datos que se quieren citizar
   * @returns
   */
  cotizarPintura(pintura: IPintura): Observable<string> {
    return new Observable(observer => {
      this.pinturaCollection.add(pintura).then(pintura => {
        observer.next(pintura.id)
        observer.complete()
      }).catch(e => {
        observer.error(e)
      })
    })
  }

  /**
 * Guarda en firebase lo que se quiere cotizar
 * @param mueble los datos que se quieren citizar
 * @returns
 */
  cotizarMueble(mueble: IMuebleSala): Observable<string> {
    return new Observable(observer => {
      this.muebleCollection.add(mueble).then(mueble => {
        observer.next(mueble.id)
        observer.complete()
      }).catch(e => {
        observer.error(e)
      })
    })
  }

  /**
* Guarda en firebase lo que se quiere cotizar
* @param colchon los datos que se quieren citizar
* @returns
*/
  cotizarColchon(colchon: IColchon): Observable<string> {
    return new Observable(observer => {
      this.colchonCollection.add(colchon).then(colchon => {
        observer.next(colchon.id)
        observer.complete()
      }).catch(e => {
        observer.error(e)
      })
    })
  }

  /**
  * Guarda en firebase lo que se quiere cotizar
  * @param silla los datos que se quieren citizar
  * @returns
  */
  cotizarSilla(silla: ISilla): Observable<string> {
    return new Observable(observer => {
      this.sillaCollection.add(silla).then(silla => {
        observer.next(silla.id)
        observer.complete()
      }).catch(e => {
        observer.error(e)
      })
    })
  }

  /**
  * Guarda en firebase lo que se quiere cotizar
  * @param alfombra los datos que se quieren citizar
  * @returns
  */
  cotizarAlfombra(alfombra: IAlfombra): Observable<string> {
    return new Observable(observer => {
      this.alfombraCollection.add(alfombra).then(alfombra => {
        observer.next(alfombra.id)
        observer.complete()
      }).catch(e => {
        observer.error(e)
      })
    })
  }

  /**
  * Guarda en firebase lo que se quiere cotizar
  * @param alfombra los datos que se quieren citizar
  * @returns
  */
  cotizarTapete(tapete: ITapete): Observable<string> {
    return new Observable(observer => {
      this.tapeteCollection.add(tapete).then(tapete => {
        observer.next(tapete.id)
        observer.complete()
      }).catch(e => {
        observer.error(e)
      })
    })
  }

  /**
  * Guarda en firebase lo que se quiere cotizar
  * @param alfombra los datos que se quieren citizar
  * @returns
  */
  cotizarCortinas(cortina: ICortinas): Observable<string> {
    return new Observable(observer => {
      this.cortinasCollection.add(cortina).then(cortina => {
        observer.next(cortina.id)
        observer.complete()
      }).catch(e => {
        observer.error(e)
      })
    })
  }

  /**
  * Guarda en firebase lo que se quiere cotizar
  * @param alfombra los datos que se quieren citizar
  * @returns
  */
  cotizarPuff(puff: IPuff): Observable<string> {
    return new Observable(observer => {
      this.puffCollection.add(puff).then(puff => {
        observer.next(puff.id)
        observer.complete()
      }).catch(e => {
        observer.error(e)
      })
    })
  }

  /**
  * Guarda en firebase lo que se quiere cotizar
  * @param alfombra los datos que se quieren citizar
  * @returns
  */
  cotizarPoltrona(poltrona: IPuff): Observable<string> {
    return new Observable(observer => {
      this.poltronaCollection.add(poltrona).then(poltrona => {
        observer.next(poltrona.id)
        observer.complete()
      }).catch(e => {
        observer.error(e)
      })
    })
  }

  /**
  * Guarda en firebase lo que se quiere cotizar
  * @param alfombra los datos que se quieren citizar
  * @returns
  */
  cotizarIsabelina(isabelina: IPuff): Observable<string> {
    return new Observable(observer => {
      this.isabelinaCollection.add(isabelina).then(isabelina => {
        observer.next(isabelina.id)
        observer.complete()
      }).catch(e => {
        observer.error(e)
      })
    })
  }
  /**
   * Obtiene todos los Hogares cotizados
   * @returns
   */
  getHogarItems(): Observable<IHogar[]> {
    // return this.hogarCollection.valueChanges({ idField: 'temp_id' })
    return new Observable<IHogar[]>(observer => {
      this.hogarCollection.get().subscribe(querySnapshot => {
        observer.next(querySnapshot.docs.map(doc => doc.data()))
        observer.complete()
      })
    })
  }
  getVehiculoItems(): Observable<IVehiculo[]> {
    //return this.vehiculoCollection.valueChanges({ idField: 'id' })
    return new Observable<IVehiculo[]>(observer => {
      this.vehiculoCollection.get().subscribe(querySnapshot => {
        observer.next(querySnapshot.docs.map(doc => doc.data()))
        observer.complete()
      })
    })
  }
  getPinturaItems(): Observable<IPintura[]> {
    //return this.pinturaCollection.valueChanges({ idField: 'id' })
    return new Observable<IPintura[]>(observer => {
      this.pinturaCollection.get().subscribe(querySnapshot => {
        observer.next(querySnapshot.docs.map(doc => doc.data()))
        observer.complete()
      })
    })
  }
  getMueblesSalaItems(): Observable<IMuebleSala[]> {
    //return this.muebleCollection.valueChanges({ idField: 'id' })
    return new Observable<IMuebleSala[]>(observer => {
      this.muebleCollection.get().subscribe(querySnapshot => {
        observer.next(querySnapshot.docs.map(doc => doc.data()))
        observer.complete()
      })
    })
  }
  getColchonesItems(): Observable<IColchon[]> {
    //return this.colchonCollection.valueChanges({ idField: 'id' })
    return new Observable<IColchon[]>(observer => {
      this.colchonCollection.get().subscribe(querySnapshot => {
        observer.next(querySnapshot.docs.map(doc => doc.data()))
        observer.complete()
      })
    })
  }
  getSillasItems(): Observable<ISilla[]> {
    //return this.sillaCollection.valueChanges({ idField: 'id' })
    return new Observable<ISilla[]>(observer => {
      this.sillaCollection.get().subscribe(querySnapshot => {
        observer.next(querySnapshot.docs.map(doc => doc.data()))
        observer.complete()
      })
    })
  }
  getAlfombrasItems(): Observable<IAlfombra[]> {
    //return this.alfombraCollection.valueChanges({ idField: 'id' })
    return new Observable<IAlfombra[]>(observer => {
      this.alfombraCollection.get().subscribe(querySnapshot => {
        observer.next(querySnapshot.docs.map(doc => doc.data()))
        observer.complete()
      })
    })
  }
  getTapeteItems(): Observable<ITapete[]> {
    //return this.tapeteCollection.valueChanges({ idField: 'id' })
    return new Observable<ITapete[]>(observer => {
      this.tapeteCollection.get().subscribe(querySnapshot => {
        observer.next(querySnapshot.docs.map(doc => doc.data()))
        observer.complete()
      })
    })
  }
  getCortinasItems(): Observable<ICortinas[]> {
    //return this.cortinasCollection.valueChanges({ idField: 'id' })
    return new Observable<ICortinas[]>(observer => {
      this.cortinasCollection.get().subscribe(querySnapshot => {
        observer.next(querySnapshot.docs.map(doc => doc.data()))
        observer.complete()
      })
    })
  }
  getPuffItems(): Observable<IPuff[]> {
    //return this.puffCollection.valueChanges({ idField: 'id' })
    return new Observable<IPuff[]>(observer => {
      this.puffCollection.get().subscribe(querySnapshot => {
        observer.next(querySnapshot.docs.map(doc => doc.data()))
        observer.complete()
      })
    })
  }
  getIsabelinasItems(): Observable<IPuff[]> {
    //return this.isabelinaCollection.valueChanges({ idField: 'id' })
    return new Observable<IPuff[]>(observer => {
      this.isabelinaCollection.get().subscribe(querySnapshot => {
        observer.next(querySnapshot.docs.map(doc => doc.data()))
        observer.complete()
      })
    })
  }
  getPoltronasItems(): Observable<IPuff[]> {
    //return this.poltronaCollection.valueChanges({ idField: 'id' })
    return new Observable<IPuff[]>(observer => {
      this.poltronaCollection.get().subscribe(querySnapshot => {
        observer.next(querySnapshot.docs.map(doc => doc.data()))
        observer.complete()
      })
    })
  }
  /**
   * Elimina de firebase una citización creada
   * @param id el id del item que se quiere eliminar
   * @returns
   */
  deleteCotizacionHogar(id: string): Observable<string> {
    return new Observable(observer => {
      this.hogarCollection.doc(id).delete().then(() => {
        observer.next(id)
        observer.complete()
      }).catch(e => {
        observer.error(e)
      })
    })
  }


  // CART
  updateToCart(type: CotizadorType, allItems: any[]) {
    console.log(type, allItems)
    switch (type) {
      case CotizadorType.HOGAR:
        this._hogarCart.next(allItems)
        localStorage.setItem("hogarCart", JSON.stringify(allItems))
        break
      case CotizadorType.VEHICULO:
        this._vehiculoCart.next(allItems)
        localStorage.setItem("vehiculoCart", JSON.stringify(allItems))
        break
      case CotizadorType.MUEBLE:
        this._mueblesCart.next(allItems)
        localStorage.setItem("mueblesCart", JSON.stringify(allItems))
        break
      case CotizadorType.PINTURA:
        this._pinturaCart.next(allItems)
        localStorage.setItem("pinturaCart", JSON.stringify(allItems))
        break
      case CotizadorType.CORTINAS:
        this._cortinasCart.next(allItems)
        localStorage.setItem("cortinasCart", JSON.stringify(allItems))
        break
      case CotizadorType.COLCHON:
        this._colchonCart.next(allItems)
        localStorage.setItem("colchonCart", JSON.stringify(allItems))
        break
      case CotizadorType.SILLAS:
        this._sillasCart.next(allItems)
        localStorage.setItem("sillasCart", JSON.stringify(allItems))
        break
      case CotizadorType.ALFOMBRA:
        this._alfombraCart.next(allItems)
        localStorage.setItem("alfombraCart", JSON.stringify(allItems))
        break
      case CotizadorType.TAPETE:
        this._tapeteCart.next(allItems)
        localStorage.setItem("tapeteCart", JSON.stringify(allItems))
        break
      case CotizadorType.PUFF:
        this._puffCart.next(allItems)
        localStorage.setItem("puffCart", JSON.stringify(allItems))
        break
      case CotizadorType.POLTRONAS:
        this._poltronaCart.next(allItems)
        localStorage.setItem("poltronaCart", JSON.stringify(allItems))
        break
      case CotizadorType.ISABELINAS:
        this._isabelinaCart.next(allItems)
        localStorage.setItem("isabelinaCart", JSON.stringify(allItems))
        break
    }
  }

  getLocalCartData() {
    let hogarCart = JSON.parse(localStorage.getItem("hogarCart"))
    this._hogarCart.next(hogarCart ?? [])

    let vehiculoCart = JSON.parse(localStorage.getItem("vehiculoCart"))
    this._vehiculoCart.next(vehiculoCart ?? [])

    let mueblesCart = JSON.parse(localStorage.getItem("mueblesCart"))
    this._mueblesCart.next(mueblesCart ?? [])

    let pinturaCart = JSON.parse(localStorage.getItem("pinturaCart"))
    this._pinturaCart.next(pinturaCart ?? [])

    let cortinasCart = JSON.parse(localStorage.getItem("cortinasCart"))
    this._cortinasCart.next(cortinasCart ?? [])

    let colchonCart = JSON.parse(localStorage.getItem("colchonCart"))
    this._colchonCart.next(colchonCart ?? [])

    let sillasCart = JSON.parse(localStorage.getItem("sillasCart"))
    this._sillasCart.next(sillasCart ?? [])

    let alfombraCart = JSON.parse(localStorage.getItem("alfombraCart"))
    this._alfombraCart.next(alfombraCart ?? [])

    let tapeteCart = JSON.parse(localStorage.getItem("tapeteCart"))
    this._tapeteCart.next(tapeteCart ?? [])

    let puffCart = JSON.parse(localStorage.getItem("puffCart"))
    this._puffCart.next(puffCart ?? [])

    let poltronaCart = JSON.parse(localStorage.getItem("poltronaCart"))
    this._poltronaCart.next(poltronaCart ?? [])

    let isabelinaCart = JSON.parse(localStorage.getItem("isabelinaCart"))
    this._isabelinaCart.next(isabelinaCart ?? [])
  }

  /**
   * Display Confrim WO dialog
   */
  showDialog(comp: any, title: string, message: string, bubbleMessage?: string): void {
    this.dialog
      .open(ComfirmDialogComponent, {
        width: '400px',
        data: { title: title, message: message, messageCotizador: `Se agregó su ${bubbleMessage} exitosamente` }
      })
      .afterClosed()
      .subscribe((confirm: Boolean) => {
        this.showSpeechBubble(bubbleMessage)
        if (confirm) {
          comp.ngOnInit()
          window.scrollTo(0, 0)
        } else {
          const articulos = document.getElementById('otros-articulos')
          const servicios = document.getElementById('otros-servicios')
          if (articulos)
            articulos.scrollIntoView()
          else if (servicios)
            servicios.scrollIntoView()
        }

        comp.disabled = false
      })


  }

  scrollToView(id: string) {
    console.log("id", id)
    setTimeout(() => {

      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView()
      }
    }, 10)
  }

  showSpeechBubble(message: string) {
    const bubble = document.querySelector('.speech-bubble')
    const bubbleMessage = document.querySelector('#message')
    bubbleMessage.textContent = message
    bubble.classList.remove("hidden")

    setTimeout(x => {
      bubble.classList.add("hidden")
    }, 10000)
  }

}

