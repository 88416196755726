<h3>Articulos a limpiar</h3>
<mat-dialog-content class="container cart-dlg">
  <div style="text-align: center;" *ngIf="noItems()">
    <span style="color: #f93f14;">El carrito está vacío</span>
  </div>
  <!-- HOGAR ITEMS -->
  <mat-card *ngFor="let hogar of hogarCartItems" class="cart-card">
    <div mat-mini-fab color="primary" (click)="removeItem(hogar)" class="cart-close-btn">x</div>
    <div class="cart-image">
      <img src="../../../assets/icons/{{hogar.itemImage}}_white.png">
    </div>
    <div class="cart-body">
      <span class="cart-title">Hogar</span>
      <span class="cart-sub-title">{{hogar.spaces?.join(', ')}}</span>
      <span class="cart-sub-title">{{hogar.pets?.join(', ')}}</span>
      <span class="cart-sub-title">{{hogar.specialServices?.join(', ')}}</span>
    </div>
    <div class="cart-buttons">
      <span *ngIf="data.mostrarPrecio" class="cart-prize">{{calculateHogarPrice(hogar) | currency}} </span>
      <span class="cart-quanty">{{hogar.itemCount}}</span>
      <button mat-icon-button color="primary" class="remove-cart-btn" (click)="removeCountItem(hogar, hogarCartItems)">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      <button mat-icon-button color="primary" class="add-cart-btn" (click)="addCountItem(hogar, hogarCartItems)">
        <mat-icon>add_circle_outline</mat-icon>
      </button>

      <!-- <button mat-raised-button color="primary" class="cart-edit-btn no-change-btn"
        (click)="editItem(hogar)">Editar</button> -->
      <span class="cart-edit-btn" (click)="editItem(hogar)">Editar</span>
    </div>
  </mat-card>

  <!-- VEHICULO ITEMS -->
  <mat-card *ngFor="let vehiculo of vehiculoCartItems" class="cart-card">
    <div mat-mini-fab color="primary" (click)="removeItem(vehiculo)" class="cart-close-btn">x</div>
    <div class="cart-image">
      <img src="../../../assets/icons/{{vehiculo.itemImage}}_white.png">
    </div>
    <div class="cart-body">
      <span class="cart-title">Vehículo</span>
      <span class="cart-sub-title">{{vehiculo.type}}</span>
      <span class="cart-sub-title">{{vehiculo.material}}</span>
      <span class="cart-sub-title">{{vehiculo.size}}</span>
    </div>
    <div class="cart-buttons">
      <span *ngIf="data.mostrarPrecio" class="cart-prize">{{calculateVehiculoPrice(vehiculo) | currency}}</span>
      <span class="cart-quanty">{{vehiculo.itemCount}}</span>
      <button mat-icon-button color="primary" class="remove-cart-btn"
        (click)="removeCountItem(vehiculo, vehiculoCartItems)">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      <button mat-icon-button color="primary" class="add-cart-btn" (click)="addCountItem(vehiculo, vehiculoCartItems)">
        <mat-icon>add_circle_outline</mat-icon>
      </button>

      <!-- <button mat-raised-button color="primary" class="cart-edit-btn no-change-btn"
         (click)="editItem(vehiculo)">Editar</button> -->

      <span class="cart-edit-btn" (click)="editItem(vehiculo)">Editar</span>
    </div>
  </mat-card>

  <!-- MUEBLES ITEMS -->
  <mat-card *ngFor="let mueble of muebleCartItems" class="cart-card">
    <div mat-mini-fab color="primary" (click)="removeItem(mueble)" class="cart-close-btn">x</div>
    <div class="cart-image">
      <img src="../../../assets/icons/{{mueble.itemImage}}_white.png">
    </div>
    <div class="cart-body">
      <span class="cart-title">Mueble Sala</span>
      <span class="cart-sub-title">{{mueble.type}}</span>
      <span class="cart-sub-title">{{mueble.material}}</span>
      <span class="cart-sub-title">{{mueble.sofaCamaTypes?.join(', ')}}</span>
    </div>
    <div class="cart-buttons">
      <span *ngIf="data.mostrarPrecio" class="cart-prize">{{calculateMueblePrice(mueble) | currency}}</span>
      <span class="cart-quanty">{{mueble.itemCount}}</span>
      <button mat-icon-button color="primary" class="remove-cart-btn"
        (click)="removeCountItem(mueble, muebleCartItems)">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      <button mat-icon-button color="primary" class="add-cart-btn" (click)="addCountItem(mueble, muebleCartItems)">
        <mat-icon>add_circle_outline</mat-icon>
      </button>

      <!-- <button mat-raised-button color="primary" class="cart-edit-btn no-change-btn"
        (click)="editItem(mueble)">Editar</button> -->
      <span class="cart-edit-btn" (click)="editItem(mueble)">Editar</span>
    </div>
  </mat-card>

  <!-- PINTURA -->
  <mat-card *ngFor="let pintura of pinturaCartItems" class="cart-card">
    <div mat-mini-fab color="primary" (click)="removeItem(pintura)" class="cart-close-btn">x</div>
    <div class="cart-image">
      <img src="../../../assets/icons/{{pintura.itemImage}}_white.png">
    </div>
    <div class="cart-body">
      <span class="cart-title">{{pintura.type}}</span>
      <span class="cart-sub-title">{{pintura.spaces?.join(', ')}}</span>
      <span class="cart-sub-title">{{pintura.property + " " + pintura.size + "m2"}}</span>
    </div>
    <div class="cart-buttons">
      <span *ngIf="data.mostrarPrecio" class="cart-prize">{{calculatePinturaPrice(pintura) | currency}}</span>
      <span class="cart-quanty">{{pintura.itemCount}}</span>
      <button mat-icon-button color="primary" class="remove-cart-btn"
        (click)="removeCountItem(pintura, pinturaCartItems)">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      <button mat-icon-button color="primary" class="add-cart-btn" (click)="addCountItem(pintura, pinturaCartItems)">
        <mat-icon>add_circle_outline</mat-icon>
      </button>

      <!-- <button mat-raised-button color="primary" class="cart-edit-btn no-change-btn"
        (click)="editItem(pintura)">Editar</button> -->
      <span class="cart-edit-btn" (click)="editItem(pintura)">Editar</span>
    </div>
  </mat-card>

  <!-- CORTINAS ITEMS -->
  <mat-card *ngFor="let cortinas of cortinasCartItems" class="cart-card">
    <div mat-mini-fab color="primary" (click)="removeItem(cortinas)" class="cart-close-btn">x</div>
    <div class="cart-image">
      <img style="filter: invert(0);" src="../../../assets/icons/{{cortinas.itemImage}}_white.png">
    </div>
    <div class="cart-body">
      <span class="cart-title">{{cortinas.type}}</span>
      <span class="cart-sub-title" *ngIf="cortinas.hasSize">{{cortinas.largo}}x{{cortinas.ancho}}
        ({{cortinas.cantidad}})</span>
      <!-- <span class="cart-sub-title">{{cortinas.property + " " + cortinas.size + "m2"}}</span> -->
    </div>
    <div class="cart-buttons">
      <span *ngIf="data.mostrarPrecio" class="cart-prize">{{calculateCortinasPrice(cortinas) | currency}}</span>
      <span class="cart-quanty">{{cortinas.itemCount}}</span>
      <button mat-icon-button color="primary" class="remove-cart-btn"
        (click)="removeCountItem(cortinas, cortinasCartItems)">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      <button mat-icon-button color="primary" class="add-cart-btn" (click)="addCountItem(cortinas, cortinasCartItems)">
        <mat-icon>add_circle_outline</mat-icon>
      </button>

      <!-- <button mat-raised-button color="primary" class="cart-edit-btn no-change-btn"
        (click)="editItem(cortinas)">Editar</button> -->
      <span class="cart-edit-btn" (click)="editItem(cortinas)">Editar</span>
    </div>
  </mat-card>


  <!-- COLCHON ITEMS -->
  <mat-card *ngFor="let colchon of colchonCartItems" class="cart-card">
    <div mat-mini-fab color="primary" (click)="removeItem(colchon)" class="cart-close-btn">x</div>
    <div class="cart-image">
      <img src="../../../assets/icons/{{colchon.itemImage}}_white.png">
    </div>
    <div class="cart-body">
      <span class="cart-title">{{colchon.type}}</span>
      <span class="cart-sub-title">{{colchon.size}} ({{colchon.doubleAmount}})</span>
      <span class="cart-sub-title" *ngIf="colchon.cabeceroDoble"><strong>Cabeceros:</strong>
        {{colchon.cabeceros}}</span>
      <span class="cart-sub-title" *ngIf="colchon.baseCamaDoble">Con base cama doble</span>
    </div>
    <div class="cart-buttons">
      <span *ngIf="data.mostrarPrecio" class="cart-prize">{{calculateColchonPrice(colchon) | currency}}</span>
      <span class="cart-quanty">{{colchon.itemCount}}</span>
      <button mat-icon-button color="primary" class="remove-cart-btn"
        (click)="removeCountItem(colchon, colchonCartItems)">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      <button mat-icon-button color="primary" class="add-cart-btn" (click)="addCountItem(colchon, colchonCartItems)">
        <mat-icon>add_circle_outline</mat-icon>
      </button>

      <span class="cart-edit-btn" (click)="editItem(colchon)">Editar</span>
    </div>
  </mat-card>

  <!-- SILLAS COMEDOR ITEMS -->
  <mat-card *ngFor="let silla of sillasCartItems" class="cart-card">
    <div mat-mini-fab color="primary" (click)="removeItem(silla)" class="cart-close-btn">x</div>
    <div class="cart-image">
      <img src="../../../assets/icons/{{silla.itemImage}}_white.png">
    </div>
    <div class="cart-body">
      <span class="cart-title">Sillas de comedor</span>
      <span class="cart-sub-title" style="text-transform: capitalize;">{{silla.tapizado}}</span>
      <span class="cart-sub-title" style="text-transform: capitalize;">{{silla.material}}</span>
      <span class="cart-sub-title">Puestos: {{silla.puestos}}</span>
    </div>
    <div class="cart-buttons">
      <span *ngIf="data.mostrarPrecio" class="cart-prize">{{calculateSillasComederPrice(silla) | currency}}</span>
      <span class="cart-quanty">{{silla.itemCount}}</span>
      <button mat-icon-button color="primary" class="remove-cart-btn" (click)="removeCountItem(silla, sillasCartItems)">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      <button mat-icon-button color="primary" class="add-cart-btn" (click)="addCountItem(silla, sillasCartItems)">
        <mat-icon>add_circle_outline</mat-icon>
      </button>

      <!-- <button mat-raised-button color="primary" class="cart-edit-btn no-change-btn"
        (click)="editItem(silla)">Editar</button> -->
      <span class="cart-edit-btn" (click)="editItem(silla)">Editar</span>
    </div>
  </mat-card>

  <!-- TAPETE ITEMS -->
  <mat-card *ngFor="let tapete of tapeteCartItems" class="cart-card">
    <div mat-mini-fab color="primary" (click)="removeItem(tapete)" class="cart-close-btn">x</div>
    <div class="cart-image">
      <img src="../../../assets/icons/{{tapete.itemImage}}_white.png">
    </div>
    <div class="cart-body">
      <span class="cart-title">Tapete</span>
      <span class="cart-sub-title" *ngIf="tapete.hasSize">{{tapete.size}} ({{tapete.cantidad}})</span>
      <span class="cart-sub-title" *ngIf="!tapete.hasSize">{{tapete.standard}} ({{tapete.cantidad}})</span>
      <span class="cart-sub-title">{{tapete.grosor}}</span>
    </div>
    <div class="cart-buttons">
      <span *ngIf="data.mostrarPrecio" class="cart-prize">{{calculateTapetePrice(tapete)| currency}}</span>
      <span class="cart-quanty">{{tapete.itemCount}}</span>
      <button mat-icon-button color="primary" class="remove-cart-btn"
        (click)="removeCountItem(tapete, tapeteCartItems)">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      <button mat-icon-button color="primary" class="add-cart-btn" (click)="addCountItem(tapete, tapeteCartItems)">
        <mat-icon>add_circle_outline</mat-icon>
      </button>

      <!-- <button mat-raised-button color="primary" class="cart-edit-btn no-change-btn"
        (click)="editItem(tapete)">Editar</button> -->
      <span class="cart-edit-btn" (click)="editItem(tapete)">Editar</span>
    </div>
  </mat-card>

  <!-- ALFOMBRA ITEMS -->
  <mat-card *ngFor="let alfombra of alfombraCartItems" class="cart-card">
    <div mat-mini-fab color="primary" (click)="removeItem(alfombra)" class="cart-close-btn">x</div>
    <div class="cart-image">
      <img src="../../../assets/icons/{{alfombra.itemImage}}_white.png">
    </div>
    <div class="cart-body">
      <span class="cart-title">Alfombra</span>
      <span class="cart-sub-title" style="text-transform: capitalize;">{{alfombra.propiedad}}
        ({{alfombra.medidaPropiedad}}m2)</span>
      <span class="cart-sub-title" *ngIf="alfombra.hasSize">Alfombra de {{alfombra.medidaTapete}}m2</span>
      <span class="cart-sub-title" style="text-transform: capitalize;"
        *ngIf="!alfombra.hasSize">{{alfombra.spaces?.join(',')}}</span>
    </div>
    <div class="cart-buttons">
      <span *ngIf="data.mostrarPrecio" class="cart-prize">{{calculateAlfombraPrice(alfombra) | currency}}</span>
      <span class="cart-quanty">{{alfombra.itemCount}}</span>
      <button mat-icon-button color="primary" class="remove-cart-btn"
        (click)="removeCountItem(alfombra, alfombraCartItems)">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      <button mat-icon-button color="primary" class="add-cart-btn" (click)="addCountItem(alfombra, alfombraCartItems)">
        <mat-icon>add_circle_outline</mat-icon>
      </button>

      <!-- <button mat-raised-button color="primary" class="cart-edit-btn no-change-btn"
        (click)="editItem(alfombra)">Editar</button> -->
      <span class="cart-edit-btn" (click)="editItem(alfombra)">Editar</span>
    </div>
  </mat-card>


  <!-- PUFF ITEMS -->
  <mat-card *ngFor="let puff of puffCartItems" class="cart-card">
    <div mat-mini-fab color="primary" (click)="removeItem(puff)" class="cart-close-btn">x</div>
    <div class="cart-image">
      <img src="../../../assets/icons/{{puff.itemImage}}_white.png">
    </div>
    <div class="cart-body">
      <span class="cart-title">Puff</span>
      <span class="cart-sub-title" style="text-transform: capitalize;">{{puff.type}} ({{puff.cantidad}})</span>
      <span class="cart-sub-title" style="text-transform: capitalize;">{{puff.material}}</span>
    </div>
    <div class="cart-buttons">
      <span *ngIf="data.mostrarPrecio" class="cart-prize">{{calculatePuffPrice(puff) | currency}}</span>
      <span class="cart-quanty">{{puff.itemCount}}</span>
      <button mat-icon-button color="primary" class="remove-cart-btn" (click)="removeCountItem(puff, puffCartItems)">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      <button mat-icon-button color="primary" class="add-cart-btn" (click)="addCountItem(puff, puffCartItems)">
        <mat-icon>add_circle_outline</mat-icon>
      </button>

      <!-- <button mat-raised-button color="primary" class="cart-edit-btn no-change-btn"
        (click)="editItem(puff)">Editar</button> -->
      <span class="cart-edit-btn" (click)="editItem(puff)">Editar</span>
    </div>
  </mat-card>

  <!-- POLTRONAS ITEMS -->
  <mat-card *ngFor="let poltrona of poltronaCartItems" class="cart-card">
    <div mat-mini-fab color="primary" (click)="removeItem(poltrona)" class="cart-close-btn">x</div>
    <div class="cart-image">
      <img src="../../../assets/icons/{{poltrona.itemImage}}_white.png">
    </div>
    <div class="cart-body">
      <span class="cart-title">Poltrona</span>
      <span class="cart-sub-title" style="text-transform: capitalize;">{{poltrona.type}} ({{poltrona.cantidad}})</span>
      <span class="cart-sub-title" style="text-transform: capitalize;">{{poltrona.material}}</span>
    </div>
    <div class="cart-buttons">
      <span *ngIf="data.mostrarPrecio" class="cart-prize">{{calculatePoltronaPrice(poltrona) | currency}}</span>
      <span class="cart-quanty">{{poltrona.itemCount}}</span>
      <button mat-icon-button color="primary" class="remove-cart-btn"
        (click)="removeCountItem(poltrona, poltronaCartItems)">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      <button mat-icon-button color="primary" class="add-cart-btn" (click)="addCountItem(poltrona, poltronaCartItems)">
        <mat-icon>add_circle_outline</mat-icon>
      </button>

      <!-- <button mat-raised-button color="primary" class="cart-edit-btn no-change-btn"
        (click)="editItem(poltrona)">Editar</button> -->
      <span class="cart-edit-btn" (click)="editItem(poltrona)">Editar</span>
    </div>
  </mat-card>

  <!-- ISABELINAS ITEMS -->
  <mat-card *ngFor="let isabelina of isabelinaCartItems" class="cart-card">
    <div mat-mini-fab color="primary" (click)="removeItem(isabelina)" class="cart-close-btn">x</div>
    <div class="cart-image">
      <img src="../../../assets/icons/{{isabelina.itemImage}}_white.png">
    </div>
    <div class="cart-body">
      <span class="cart-title">Isabelina</span>
      <span class="cart-sub-title" style="text-transform: capitalize;">{{isabelina.type}}
        ({{isabelina.cantidad}})</span>
      <span class="cart-sub-title" style="text-transform: capitalize;">{{isabelina.material}}</span>
    </div>
    <div class="cart-buttons">
      <span *ngIf="data.mostrarPrecio" class="cart-prize">{{calculateIsabelinasPrice(isabelina) | currency}}</span>
      <span class="cart-quanty">{{isabelina.itemCount}}</span>
      <button mat-icon-button color="primary" class="remove-cart-btn"
        (click)="removeCountItem(isabelina, isabelinaCartItems)">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      <button mat-icon-button color="primary" class="add-cart-btn"
        (click)="addCountItem(isabelina, isabelinaCartItems)">
        <mat-icon>add_circle_outline</mat-icon>
      </button>

      <!-- <button mat-raised-button color="primary" class="cart-edit-btn no-change-btn"
        (click)="editItem(isabelina)">Editar</button> -->
      <span class="cart-edit-btn" (click)="editItem(isabelina)">Editar</span>
    </div>
  </mat-card>

</mat-dialog-content>
<hr />
<div [ngStyle]="data.mostrarPrecio?{'text-align': 'center'}:{'text-align': 'right'}">
  <!-- <div class="total-label"><strong>Subtotal: </strong><span>{{subtotal | currency}}</span></div>
  <div class="total-label"><strong>IVA: </strong><span>{{iva | currency}}</span></div> -->
  <div class="total-label" *ngIf="data.mostrarPrecio && !noItems()"><strong>Total: </strong><span>{{this.totalPrice() |
      currency}}</span></div>
  <button mat-raised-button color="primary" *ngIf="!noItems()" class="cart-proceed-btn no-change-btn"
    (click)="cotizar()">{{data.mostrarPrecio?'Enviar a revisión y agendamiento':'Cotizar'}}</button>

  <div class="col-md-12">
    <span style="color:#1364ff; margin-top: 5px; width: 60px;" color="basic" *ngIf="data.mostrarPrecio && !noItems()"
      mat-dialog-close>Seguir
      cotizando</span>
  </div>

  <div class="col-md-12">
    <button mat-raised-button color="basic" (click)="genScreenshot()" *ngIf="data.mostrarPrecio && !noItems()"
      class="cart-proceed-btn no-change-btn">Compartir <span class="material-icons" style="font-size: 1.4em;">
        share
      </span></button>
    <!-- <button mat-raised-button color="basic" (click)="sendEmail()" *ngIf="data.mostrarPrecio && !noItems()"
      style="margin-left: 7px;" class="cart-proceed-btn no-change-btn">Compartir por Email <span class="material-icons"
        style="font-size: 1.4em;">
        mail
      </span></button> -->
  </div>



</div>