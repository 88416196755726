export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAvcue7H8SiIQ8NrAi1hk07LzeTYn_jq1A",
    authDomain: "homsy-e9809.firebaseapp.com",
    databaseURL: "https://myapp-project-123.firebaseio.com",
    projectId: "homsy-e9809",
    storageBucket: "homsy-e9809.appspot.com",
    messagingSenderId: "245291508530",
    appId: "1:245291508530:web:8c01724db7851c6aac6d2b",
    measurementId: "G-1DZNC3F0RJ"
  }
};
